import {Typography} from "@mui/material";

function SpellTracker(props: { power: number, rank: number }) {
    const {power, rank} = props;
    return (
        <span className="flex flex-col justify-center items-center p-1">
                        <Typography variant={'h5'}>Casts</Typography>
                        <Typography variant={'h5'}>{castingTable[power][rank]}</Typography>
                    </span>
    );
}

export default SpellTracker

export const castingTable: { [key: number]: { [key: number]: number } } = {
    0: {0: 1},
    1: {0: 2, 1: 1},
    2: {0: 3, 1: 2, 2: 1},
    3: {0: 4, 1: 2, 2: 1, 3: 1},
    4: {0: 5, 1: 2, 2: 2, 3: 1, 4: 1},
    5: {0: 6, 1: 3, 2: 2, 3: 2, 4: 1, 5: 1},
    6: {0: 7, 1: 3, 2: 2, 3: 2, 4: 2, 5: 1, 6: 1},
    7: {0: 8, 1: 3, 2: 2, 3: 2, 4: 2, 5: 1, 6: 1, 7: 1},
    8: {0: 9, 1: 3, 2: 3, 3: 2, 4: 2, 5: 2, 6: 1, 7: 1, 8: 1},
    9: {0: 10, 1: 3, 2: 3, 3: 3, 4: 2, 5: 2, 6: 1, 7: 1, 8: 1, 9: 1},
    10: {0: 11, 1: 3, 2: 3, 3: 3, 4: 3, 5: 2, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1},
}