import {
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import {Add} from "@mui/icons-material";

function Modifiers({addModifier}: {addModifier: (modifier: {[key: string] : number}) => void}) {
    const attributes = ["", "Strength", "Agility", "Intellect", "Will", "Perception", "Insanity", "Power", "Damage", "Corruption", "Health", "Defense", "Speed"];
    const [modifier, setModifier] = useState<number>(0);
    const [attribute, setAttribute] = useState<string>("");
    const [modifiers, setModifiers] = useState<{ [key: string]: number }>({});

    const handleAddModifier = () => {
        addModifier({attribute, modifier});
        setModifier(0);
        setAttribute("");
        setModifiers((prevState) => {
            prevState[attribute] = modifier;
            return prevState;
        });
    }
    return (
        <Stack direction={'column'} spacing={2}>
            <Stack direction={'row'} spacing={2}>
                <FormControl fullWidth variant={'outlined'}>
                    <InputLabel id="select-attribute">Attribute</InputLabel>
                    <Select
                        labelId="select-attribute"
                        id="attribute"
                        value={attribute}
                        label="Age"
                        onChange={(event: SelectChangeEvent<string>) => setAttribute(event.target.value)}
                    >
                        {attributes.map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        disabled={!attribute}
                        type="number"
                        name={'modifier'}
                        label={"Modifier"}
                        value={modifier}
                        onChange={(event) => setModifier(parseInt(event.target.value))}
                        variant={"outlined"}
                    />
                </FormControl>
                <IconButton size={'small'} onClick={handleAddModifier} disabled={!attribute || !modifier}>
                    <Add/>
                </IconButton>
            </Stack>
            <Typography>Modifications</Typography>
            <Stack direction={'row'} sx={{display: !modifiers ? 'none' : 'flex'}}>
                {Object.keys(modifiers).map((value) => {
                    return(<div key={value} className={'flex-col p-2 text-center'}>
                        <Typography>{value}</Typography>
                        <Typography>{modifiers[value]}</Typography>
                    </div>)
                })}
            </Stack>
        </Stack>
    )
}

export default Modifiers;