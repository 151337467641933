import React, {ChangeEvent, useEffect, useState} from "react";
import {Divider, List, ListItem, ListItemButton, ListItemText, Stack, useMediaQuery, useTheme} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useParams} from "react-router-dom";
import {updateHealth} from "../../redux/character/statusSlice";

function HealthTracker({currentHealth, max}: { currentHealth: number, max: number }) {
    const current = useAppSelector((state) => state.status.health.current);
    const maxHealth = useAppSelector((state) => state.status.health.max);
    const {user} = useAppSelector((state) => state.application);
    const [incrementDecrement, setIncrementDecrement] = useState<number>(0);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedIndex, setSelectedIndex] = useState(1);
    const {id} = useParams();

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setIncrementDecrement(index);
    };

    useEffect(() => {
        if (currentHealth && max) {
            dispatch({type: 'status/setHealth', payload: {current: currentHealth, max: max}});
        }
    }, [currentHealth, max, dispatch]);

    const updateCurrentHealth = (decrement = false) => {
        dispatch(updateHealth({
            data: decrement ? current - incrementDecrement : current + incrementDecrement,
            id: id,
            email: user.email
        }));
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIncrementDecrement(parseInt((event.target as HTMLInputElement).value) > 0 ? parseInt((event.target as HTMLInputElement).value) : 0);
    };

    return (<>
            <Button variant={'contained'} onClick={() => setOpen(true)}>
                <div className="flex flex-col flex-auto justify-center items-center text-base">
                    <div className="text-xl">
                        <span className="font-medium">{current}</span>
                        <span>/</span>
                        <span className="font-medium">{maxHealth}</span>
                    </div>
                    <span>Health</span>
                </div>
            </Button>
            <Dialog open={open}>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby=""
                >
                    <DialogTitle>
                        {"Health Management"}
                    </DialogTitle>
                    <Divider/>
                    <DialogContent sx={{overflow: 'hidden'}}>
                        <Stack direction={'row'} spacing={5} justifyContent={'center'} p={3}>
                            <div className={"flex flex-col"}>
                                <div className="text-2xl">
                                    <span className="font-medium">{current}</span>
                                    <span>/</span>
                                    <span className="font-medium">{maxHealth}</span>
                                </div>
                                <span>Health</span>
                            </div>
                            <div className="flex flex-col justify-center items-center text-xl">
                                <span>Healing Rate</span>
                                <span>{Math.floor(maxHealth / 4)}</span>
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                            <div className="flex flex-col p-1">
                                <Button onClick={() => updateCurrentHealth()} color="primary" variant={'outlined'}>
                                    <Add/>
                                </Button>
                                <div className={"m-1"}>
                                    <TextField
                                        id="outlined-number"
                                        label=""
                                        variant={'outlined'}
                                        type="number"
                                        value={incrementDecrement}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <Button onClick={() => updateCurrentHealth(true)} color="error" variant={'outlined'}>
                                    <Remove/>
                                </Button>
                            </div>
                            <Box sx={{height: '300px', overflowY: 'auto', borderRadius: '12px'}}>
                                <Box
                                    sx={{width: 300, bgcolor: 'background.paper', p: 1}}
                                >
                                    <List>
                                        {
                                            Array.from(Array(100).keys()).map((number) => {
                                                return (
                                                    <ListItem disableGutters key={number} alignItems={'center'}>
                                                        <ListItemButton
                                                            selected={selectedIndex === number}
                                                            onClick={(event) => handleListItemClick(event, number)}>
                                                            <ListItemText
                                                                sx={{textAlign: 'center'}}>{number}</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Box>
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </>
    )
}

export default HealthTracker;
