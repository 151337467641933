import React from "react";
import Button from "@mui/material/Button";
import {IconButton} from "@mui/material";

function BlockStyleButton({label, onToggle, style, active, type}) {
    return (
        <IconButton onClick={(event) => {
            event.preventDefault();
            onToggle(style);
        }} color={active ? 'primary' : 'default'}>{type.icon}</IconButton>
    )
}

export default BlockStyleButton;