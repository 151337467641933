import {ColumnDef} from "@tanstack/react-table";


export interface ItemModel {
    identity: string;
    active: boolean;
    name: string;
    price: number;
    availability: 'Common' | 'Uncommon' | 'Rare' | 'Exotic'
    weight?: number;
    quantity: number;
    description?: string;
    consumable?: boolean;
    equipped?: boolean;
    damage?: string;
    hands?: number;
    properties?: {
        [name: string]: {
            description: string,
            banes: number,
            boons: number,
            reach?: number
        }
    };
    attribute?: 'Strength' | 'Agility' | 'Finesse';
    defense?: string;
    type: string;
}


export const itemColumns: ColumnDef<ItemModel>[] = [
    {
        header: 'Name',
        accessorFn: row => row.name,
        id: 'name',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        header: 'Weight',
        accessorFn: row => row.weight,
        id: 'weight',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        header: 'Quantity',
        accessorFn: row => row.quantity,
        id: 'quantity',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        header: 'Price',
        accessorFn: row => row.price,
        id: 'price',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    }
]