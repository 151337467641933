import React, {useState} from "react";
import {Button, FormControlLabel, FormGroup, Stack, Switch, Toolbar} from "@mui/material";
import './AncestryTalents.css'
import {Talent} from "../Character.model";
import TalentLabel from "../../../shared/components/TalentLabel";
import Modifiers from "../../../shared/components/Modifiers";
import ScrollableContainer from "../../../shared/components/ScrollableContainer";
import FullScreenDialog from "../../../shared/components/dynamic-form/FullScreenDialog";

export interface IAncestryTalents {
    talents: { [key: string]: Talent };
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
    editable?: boolean;
}

export function AncestryTalents(props: IAncestryTalents) {
    const [talents, setTalents] = useState<{ [key: string]: Talent }>(props.talents);
    const [adding, setAdding] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [modifiers, setModifiers] = useState<{ [key: string]: number }>({});
    const [stayOpen, setStayOpen] = useState<boolean>(true);
    const [talent, setTalent] = useState<Talent>({
        name: '',
        description: '',
        action: false,
        numberOfCasts: 0,
        modifiers: {}
    });


    const handleModifier = (mod) => {
        setModifiers((prevState) => {
            prevState[mod.attribute] = mod.modifier;
            return prevState;
        });
    }

    const onAdd = (talent) => {
        const size = Object.keys(talents).length + 1;
        talents[size] = {name: talent.name, description: talent.description, action: talent.numberOfCasts > 0, modifiers};
        const newMap = {...talents};
        setTalents(newMap);
        props.dispatch({type: 'update', payload: {'Ancestry Talents': talents}});
        if(!stayOpen) {
            setAdding(!adding);
        }
        setTalent({
            name: '',
            description: '',
            action: false,
            numberOfCasts: 0,
            modifiers: {}
        });
    }
    const onEdit = (talent) => {
        console.log(talent);
    }

    const onRemove = (index: string) => {
        delete talents[index];
        const newMap = {...talents};
        setTalents(newMap);
        props.dispatch({type: 'update', payload: {'Ancestry Talents': talents}});
    }

    return (
        <div>
            {props.editable && (!adding ? <Button onClick={() => setAdding(!adding)}>Add Talents</Button> : <Toolbar>
                <Button onClick={onAdd}>Add</Button>
                <Button onClick={() => setAdding(!adding)}>Cancel</Button>
            </Toolbar>)}
            <React.Fragment>
                <FullScreenDialog
                    title={editing ? 'Edit Talent' : 'Add Talent'}
                    saveButtonText="next"
                    cancelButtonText="cancel"
                    btnVariant="text"
                    open={adding}
                    onSubmit={editing ? onEdit: onAdd}
                    onCancel={() => setAdding(!adding)}
                    initialValues={talent}
                    sections={[{title: '1'}, {title: '2'}, {title: 'Modifiers'}]}
                    fields={[
                        {
                            section: '1',
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                        },
                        {
                            section: '2',
                            name: 'description',
                            type: 'richText',
                            label: 'Description',
                        },
                        {
                            section: '1',
                            name: 'numberOfCasts',
                            type: 'number',
                            label: 'Number of Casts',
                        },
                        {
                            section: 'Modifiers',
                            name: 'modifiers',
                            type: 'element',
                            label: 'Modifiers',
                            element: (<Modifiers addModifier={handleModifier}/>)
                        }
                    ]}
                    validation={{
                        name: {
                            type: 'text', required: true, requiredMessage: 'Name is a required field.'
                        },
                    }}
                    additionalButtons={<FormGroup>
                        <FormControlLabel control={<Switch
                            id={'stay-open'}
                            checked={stayOpen}
                            onChange={() => setStayOpen(!stayOpen)}
                            inputProps={{'aria-label': 'controlled'}}
                        />} label="Create Another"/>
                    </FormGroup>}
                />
            </React.Fragment>

            <ScrollableContainer>
                {Object.keys(talents).map((key: string, index) => {
                    const talent = talents[key];
                    return <div key={index}>
                        {TalentLabel(talent.name, talent.description)}
                        {props.editable && (
                            <div>
                                <Button onClick={() => onRemove(key)}>Remove</Button>
                                <Button onClick={() => {
                                    setTalent(talent);
                                    setAdding(true);
                                    setEditing(true);
                                }}>Edit</Button>
                            </div>
                        )}
                    </div>
                })}
            </ScrollableContainer>
        </div>
    )
}
