import {useEffect} from 'react';
import './Actions.css';
import {Divider, Typography} from "@mui/material";
import WeaponAttack from "./weapon/WeaponAttack";
import {WeaponModel} from "../../../../shared/models/weapon.model";
import {SpellModel} from "../../../../shared/models/spell.model";
import {actions, attributeAttacks, meleeAttacks, rangedAttacks} from "./attacks/Attacks.model";
import Attacks from "./attacks/Attacks";
import ScrollableContainer from "../../../../shared/components/ScrollableContainer";
import {ItemModel} from "../../../../shared/models/item.model";
import {getEquipment} from "../../../redux/character/equipmentSlice";
import {useParams} from "react-router-dom";
import {Talent} from "../../Character.model";
import TalentAction from "./TalentAction";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

function Actions(props: { ancestryTalents: { [key: string]: Talent }, agility: number, strength: number, spells: SpellModel[], power: number }) {
    const {id} = useParams();
    const {user} = useAppSelector((state) => state.application);
    const weapons = useAppSelector((state) => state.equipment.equipment.filter((weapon) => weapon.type === 'weapons'));
    const {activeAction, activeTalents} = useAppSelector((state) => state.application);
    const dispatch = useAppDispatch();
    const unarmedStrike = {
        identity: '1',
        active: true,
        name: 'Unarmed Strike',
        price: 0,
        availability: 'Common',
        quantity: 1,
        description: '',
        consumable: false,
        equipped: true,
        damage: '1d3',
        hands: 1,
        attribute: 'Strength',
        type: 'weapons'
    } as WeaponModel;

    const setActiveDetail = (type: string, weapon: ItemModel) => {
        dispatch({
            type: 'application/setAction',
            payload: {
                type,
                weapon
            }
        });
    };

    useEffect(() => {
        dispatch(getEquipment({
            email: user.email ?? '',
            id: id ?? ''
        }))
    }, [dispatch]);

    return (
        <div className="flex flex-row">
            {/*Spell and Weapon List*/}
            <div className="flex-auto">
                <div>
                    <div>
                        <Typography variant={'subtitle1'}>Weapons</Typography>
                    </div>
                    <div onClick={() => setActiveDetail('weapon', unarmedStrike)}>
                        <WeaponAttack weapon={unarmedStrike} agility={props.agility}
                                      strength={props.strength}
                                      active={activeAction.weapon?.identity === unarmedStrike.identity}/>
                    </div>
                    {weapons.map((weapon: WeaponModel) => {
                        return <div onClick={() => setActiveDetail('weapon', weapon)} key={weapon.identity}>
                            <WeaponAttack weapon={weapon} agility={props.agility}
                                          strength={props.strength}
                                          active={activeAction.weapon?.identity === weapon.identity}/>
                        </div>
                    })}
                </div>
                <div>
                    <div>
                        <Typography variant={'subtitle1'}>Ancestry/Path Abilities</Typography>
                        {Object.values(props.ancestryTalents).map((value) => {
                            if (value.action) {
                                return (<div key={value.name} onClick={() => {
                                    dispatch({type: 'application/setActiveTalent', payload: value.name});
                                }}>
                                    <TalentAction talent={value} active={activeTalents.includes(value.name)}/>
                                </div>)
                            }
                        })}
                    </div>
                </div>
            </div>
            {/*Spell and Weapon further info*/}
            <ScrollableContainer>
                <>
                    {activeAction?.type === 'weapon' &&
                        <div className="flex flex-col flex-auto">
                            <div>
                                <div>
                            <span className="flex flex-col p-2">
                                <Typography>Actions</Typography>
                                <Divider color={"secondary"}/>
                                <Attacks attacks={actions}/>
                            </span>
                                </div>
                                {!isRangedWeapon(activeAction.weapon as WeaponModel) &&
                                    <div>
                                <span className="flex flex-col p-2">
                                    <Typography>Melee Options</Typography>
                                    <Divider color={"secondary"}/>
                                    <Attacks attacks={meleeAttacks}/>
                                </span>
                                    </div>
                                }
                                {isRangedWeapon(activeAction.weapon as WeaponModel) &&
                                    <div>
                                <span className="flex flex-col p-2">
                                    <Typography>Ranged Options</Typography>
                                    <Divider color={"secondary"}/>
                                    <Attacks attacks={rangedAttacks}/>
                                </span>
                                    </div>
                                }
                                <div>
                            <span className="flex flex-col p-2">
                                <Typography>Attack an Attribute</Typography>
                                <Divider color={"secondary"}/>
                                <Attacks attacks={attributeAttacks}/>
                            </span>
                                </div>
                                <div>
                            <span className="flex flex-col p-2">
                                <Typography>Move</Typography>
                                <Divider color={"secondary"}/>
                            </span>
                                </div>
                            </div>
                        </div>
                    }
                </>
            </ScrollableContainer>
        </div>
    );
}

function isRangedWeapon(weapon: WeaponModel): boolean {
    const props = Array.from(Object.keys(weapon?.properties || {}));
    return props.includes('Range(short)') || props.includes('Range(medium)') || props.includes('Range(long)');
}

export default Actions;