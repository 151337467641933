import { Input } from "@mui/material";

export interface ITextInput {
    field: string;
    value: string | number;
    classNames?: string;
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
}

function TextInput(props: ITextInput) {
    const handleChange = (e: any) => {
        props.dispatch({ type: 'update', payload: { [props.field]: e.target.value } })
    }

    const value = props.value;
    return <Input style={{ textAlign: 'center' }} className={props.classNames} name={props.field} type='text'
        value={value || ''}
        onChange={handleChange} />;
}

export default TextInput;
