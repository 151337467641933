import {configureStore} from "@reduxjs/toolkit";
import {charactersSlice} from "./redux/characters/charactersSlice";
import {characterSlice} from "./redux/character/characterSlice";
import {statusSlice} from "./redux/character/statusSlice";
import {equipmentSlice} from "./redux/character/equipmentSlice";
import {spellSlice} from "./redux/character/spellSlice";
import {applicationSlice} from "./redux/applicationSlice";
import {noteSlice} from "./redux/character/noteSlice";

export const loadState = () => {
    try {
        const serialState = localStorage.getItem('appState');
        console.log(JSON.parse(serialState));
        if (serialState === null) {
            return undefined;
        }
        const parsedState = JSON.parse(serialState);
        parsedState.status.health = {
            current: parsedState.character.character.CurrentHealth,
            max: parsedState.character.character.Health
        }
        return parsedState;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: any) => {
    try {
        const serialState = JSON.stringify(state);
        localStorage.setItem('appState', serialState);
    } catch(err) {
        console.log(err);
    }
};

const persistedState = loadState();

export default configureStore({
    reducer: {
        application: applicationSlice.reducer,
        characters: charactersSlice.reducer,
        character: characterSlice.reducer,
        status: statusSlice.reducer,
        equipment: equipmentSlice.reducer,
        spells: spellSlice.reducer,
        notes: noteSlice.reducer
    },
    preloadedState: persistedState
});
