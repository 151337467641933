import React, {useEffect} from "react";
import CharacterCard from "./CharacterCard";
import {CharacterModel} from "../character/Character.model";
import SideBar from "../../shared/components/SideBar";
import IconButton from "@mui/material/IconButton";
import {Add} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {Status} from "../redux/status";
import {fetchCharacters} from "../redux/characters/charactersSlice";
import {useAppDispatch, useAppSelector} from "../redux/hooks";

export function Characters(props: { characterList: CharacterModel[], setCharacterList: (characters: CharacterModel[]) => void }) {
    const {user} = useAppSelector((state) => state.application);
    const {characters, status} = useAppSelector((state) => state.characters);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (status === Status.Idle && user?.email) {
            dispatch(fetchCharacters(user.email));
        }
    }, [status, dispatch]);

    const handleDelete = (id: string) => {
        fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/delete/${id}?email=${user?.email}`, {
            method: 'DELETE'
        }).then(res => res.json())
            .then(response => props.setCharacterList(response.map((character: object) => new CharacterModel().deserialize(character))))
            .catch(err => {
                console.error(err)
            });
    }

    return (
        <div className='flex flex-row'>
            <SideBar user={user}>
                <div className='pb-6'>
                    <IconButton onClick={() => {
                        localStorage.removeItem('appState');
                        navigate('/create');
                    }} color="primary">
                        <Add/>
                    </IconButton>
                </div>
            </SideBar>
            <div className="w-[calc(100vw_-_80px)]">
                {status === Status.Loading ? <div>Loading</div>
                    :
                    <div className="grid grid-cols-3 gap-1">
                        {characters.map((character, index) => <CharacterCard id={character.identity}
                                                                             key={index}
                                                                             name={character.Name}
                                                                             email={user.email}
                                                                             handleDelete={handleDelete}/>)}
                    </div>
                }
            </div>
        </div>
    );
}
