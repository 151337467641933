import React, {Fragment, useEffect, useState} from "react";
import {SpellModel} from "../../../../shared/models/spell.model";
import ManageSpells from "./manage-spells/ManageSpells";
import SpellTable from "./spell-table/SpellTable";
import {useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {castingTable} from "./spell-table/SpellTracker";
import {createSpell, deleteSpell, getSpells, updateSpell} from "../../../redux/character/spellSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import SpellList from "./SpellList";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {ViewList, ViewModule} from "@mui/icons-material";

export interface ISpells {
    spellList: SpellModel[];
    power: number;
    spellsCasted: { [rank: number]: number };
    editing: boolean;
    email: string;
}

function Spells(props: ISpells) {
    const {spells, editingSpell, spellView} = useAppSelector((state) => state.spells);
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(spellView);
    const dispatch = useAppDispatch();
    const [spell, setSpell] = useState({});

    const handleClose = (newSpell?: SpellModel) => {
        if (newSpell && !editingSpell) {
            newSpell.casts = castingTable[props.power][newSpell.rank];
            dispatch(createSpell({
                id: id, email: props.email, data: newSpell
            }));
        } else if (newSpell && editingSpell) {
            dispatch(updateSpell({
                id: id, email: props.email, data: newSpell
            }));
        }
        dispatch({type: 'spells/editSpell', payload: null});
    };

    const learnSpell = (spell: SpellModel) => {
        dispatch(createSpell({
            id: id, email: props.email, data: spell
        }));
    }
    const removeSpell = (spell: SpellModel) => {
        dispatch(deleteSpell({
            id: id, email: props.email, data: spell
        }));
    }

    const editSpell = (spell: SpellModel) => {
        dispatch({type: 'spells/editSpell', payload: spell});
        dispatch({type: 'spells/openSpell', payload: true});
    }

    useEffect(() => {
        // TODO implement getting the character from the server by id if navigating straight to this page
        dispatch(getSpells({id: id, email: props.email}));
    }, []);

    return (<>
        <div className={'flex justify-between flex-row p-1'}>
            <span className={'flex-1'}>
                <Button variant={open ? 'contained' : 'outlined'} onClick={() => setOpen(!open)}>
                    Manage Spells
                </Button>
            </span>
            <ToggleButtonGroup
                value={view}
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>, nextView: string) => {
                    setView(nextView);
                    dispatch({type: 'spells/setSpellView', payload: nextView});
                }
                }
            >
                <ToggleButton value="list" aria-label="list">
                    <ViewList/>
                </ToggleButton>
                <ToggleButton value="table" aria-label="module">
                    <ViewModule/>
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
        {open && <Fragment>
            <ManageSpells learnSpell={learnSpell} removeSpell={removeSpell} power={props.power} editSpell={editSpell}/>
        </Fragment>}
        {view === 'table' ? (
            <SpellTable editing={props.editing} spells={spells} power={props.power} dispatch={props.dispatch}
                        spellsCasted={props.spellsCasted || {}} removeSpell={removeSpell} editSpell={editSpell}/>) : (
            <SpellList spells={spells} power={props.power} spellsCasted={props.spellsCasted || {}}/>)}
    </>);
}

export default Spells;
