import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "../status";

const initialState = {
    status: Status.Idle,
    characters: [],
    error: null
}

export const charactersSlice = createSlice({
    name: 'characters',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCharacters.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(fetchCharacters.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.characters = action.payload;
            })
            .addCase(fetchCharacters.rejected, (state, action) => {
                state.status = Status.Failed;
            })
    }
});

// TODO implement getting the character from the server by id if navigating straight to this page
export const fetchCharacters = createAsyncThunk('characters/fetchCharacters', async (email: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/characters?email=${email}`)
        .then(res => res.json());
})

export default charactersSlice.reducer;