export abstract class AbstractModel {
    public deleted: boolean = false;
    constructor(data?: any) {
        if (!!data) {
            this.deserialize(data);
        }
    }

    deserialize(formObject: any): any {
        for (let propName in formObject) {
            this[propName as keyof AbstractModel] = formObject[propName];
        }
        return this;
    }
}