import {SpellModel} from "../../../../shared/models/spell.model";
import {Divider, Typography} from "@mui/material";
import './SpellCard.css';
import RichTextEditor from "../../../../shared/components/rich-text-editor/RichTextEditor";

function SpellCard(props: { spell: SpellModel, index?: number, onRemove?: () => void, onEdit?: (value?: SpellModel, index?: number) => void }) {
    const spell = props.spell;
    return (<div className="h-[calc(100%_-_50px)] min-w-300">
        <div style={{padding: 10}}>
            <div className={'title'}>
                <Typography variant={'h6'} color={"#9b0000"}>{spell.name}</Typography>
                <Typography variant={'h6'}
                            color={"#9b0000"}>{spell.tradition} {spell.spellType} {spell.rank}</Typography>
            </div>
            <Divider/>
            <div className={'content'}>
                <Typography><strong>Requirement</strong> {spell.requirement}</Typography>
                {!!spell.target && <Typography><strong>Target</strong> {spell?.target}</Typography>}
                {!!spell.area && <Typography><strong>Area</strong> {spell?.area}</Typography>}
                {!!spell?.duration && <Typography>Duration {spell?.duration}</Typography>}
            </div>
            <Divider color={"#9b0000"}/>
            <div className={'footer'}>
                <Typography>{spell.actionType && <strong>{spell.actionType}</strong>}</Typography>
                <RichTextEditor readOnly={true} state={spell.description}/>
                {(spell.spellType === 'Attack' && !!spell.additionalEffect) &&
                    <Typography><strong>Attack Roll 20+</strong> {spell?.additionalEffect}</Typography>}
            </div>
        </div>
    </div>)
}


export default SpellCard;
