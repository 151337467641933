import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Button, Divider, Paper, Stack, Typography} from '@mui/material';
import {CharacterModel} from '../character/Character.model';
import {characterReducer} from '../character/character.reducer';
import {AncestryForm} from './AncestryForm';
import Path from '../character/primary/paths/path/Path';
import Description from '../character/description-talents-and-skills/Description';
import Equipment from '../character/primary/inventory/equipment/Equipment';
import Spells from '../character/primary/spells/Spells';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import AttributeForm from "./AttributeForm";

const steps = ['Ancestry', 'Attributes', 'Path', 'Spells', 'Description', 'Equipment'];

export function CharacterForm(props: { character?: CharacterModel, handleCreate: (character: CharacterModel) => void }) {
    const {user} = useSelector((state) => state.application);
    const [activeStep, setActiveStep] = React.useState(0);
    const location = useLocation()
    const editCharacter = new CharacterModel().deserialize(location.state);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [character, dispatch] = React.useReducer(characterReducer, props.character ?? editCharacter);

    const isStepOptional = (step: number) => {
        return step === 3;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === steps.length - 1) {
            props.handleCreate(character);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChange = (action: { type: string, payload: { [key: string]: any } }) => {
        dispatch(action);
    };

    const changeHandle = (key: string, value: string) => {
        dispatch({type: 'update', payload: {[key]: parseInt(value)}})
    }

    const getStep = () => {
        switch (activeStep + 1) {
            case 1:
                return <Paper sx={{p:1}}><AncestryForm character={character} dispatch={handleChange}/></Paper>
            case 2:
                return (
                    <AttributeForm character={character} changeHandle={changeHandle}/>
                )
            case 3:
                return <Paper sx={{p:1}}><Path talents={character.Paths ?? {}} dispatch={handleChange} editable={true}/></Paper>
            case 4:
                return <Paper sx={{p:1}}><Spells editing={true} spellList={character.Spells ?? []} power={character.Power || 0}
                                                 spellsCasted={character.spellsCasted} email={user.email}/></Paper>
            case 5:
                return <Paper sx={{p:1}}><Description character={character} dispatch={handleChange} editable={true}/></Paper>
            case 6:
                    return <Paper sx={{p:1}}><Equipment Inventory={character.Inventory} email={user.email}/></Paper>
            default:
                return <div>Step not found</div>
        }
    }

    return (
        <div className='flex flex-row'>
            <Stack direction={'row'} className='w-screen'>
                <Stack direction={'column'} className='p-2 m-2'>
                    <Stepper activeStep={activeStep} orientation={'vertical'}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps} onClick={() => setActiveStep(index)}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Stack>
                <Divider orientation={'vertical'} sx={{height: '100vh'}}/>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{mt: 2, mb: 1}}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                            <Box sx={{flex: '1 1 auto'}}/>
                            {/* <Button onClick={handleReset}>Reset</Button> */}
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/* Content goes here */}
                        <Stack direction={"column"} p={2} className={'w-[calc(100vw_-_150px)] p-2 m-2'}>
                            <div className="p-2 h-[calc(100vh_-_80px)] overflow-y-scroll flex flex-col flex-auto">
                                {getStep()}
                            </div>
                            <Stack direction={'row'} justifyContent={'space-between'} p={2}>
                                <Button>
                                    <Link
                                        to={editCharacter.identity ? `/characters/${editCharacter.identity}` : '/characters'}>Cancel</Link>
                                </Button>
                                <div>
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                                            Skip
                                        </Button>
                                    )}
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{mr: 1}}
                                    >
                                        Back
                                    </Button>
                                    <Button onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </Stack>
                        </Stack>
                    </React.Fragment>
                )}
            </Stack>
        </div>
    );
}