import { WeaponModel } from "../../shared/models/weapon.model";
import { ArmorModel } from "../../shared/models/armor.model";
import { ItemModel } from "../../shared/models/item.model";
import { SpellModel } from "../../shared/models/spell.model";
import { AbstractModel } from "../../shared/models/abstract.model";

export class CharacterModel extends AbstractModel{
    public "identity": string;
    public "Name": string;
    public "Ancestry": string;
    public "Age": number;
    public "Gender": string;
    public "Height": number;
    public "Weight": number;
    public "Languages": string;
    public "Size": number;
    public "Speed": number;
    public "AppearanceDescription": string;
    public "Background": {
        "Origin": BackgroundType,
        "Childhood": BackgroundType,
        "Life Experiences": BackgroundType,
        "Major Setback": BackgroundType[],
        "Minor Setback": BackgroundType[],
        "Major Windfall": BackgroundType[],
        "Minor Windfall": BackgroundType[],
        "Strange Event": BackgroundType[],
        [key: string]: BackgroundType | BackgroundType[]
    }
    public "Personality": {
        "Personality": BackgroundType,
        "Relationships": BackgroundType,
        "Desires": BackgroundType,
        "Fears": BackgroundType,
        "Obligations": BackgroundType
        "Morality": BackgroundType,
        "Religion": BackgroundType,
        "Piety": BackgroundType,
        "Quirk": BackgroundType,
        "Hatred": BackgroundType,
        "Odd Habit": BackgroundType,
        [key: string]: BackgroundType | BackgroundType[]
    }
    public "Distinct Features": string;
    public "Ancestry Talents": { [key: string]: Talent };
    public "Professions": string;
    public "Level": number;
    public "Strength": number;
    public "Agility": number;
    public "Intellect": number;
    public "Will": number;
    public "Perception": number;
    public "Power": number;
    public "Health": number;
    public "CurrentHealth": number;
    public "Damage": number;
    public "Insanity": number;
    public "Corruption": number;
    public "Defense": number;
    public "Fortune": string;
    public "Currency": {
        "bits": number;
        "copper": number;
        "silver": number;
        "gold": number;
    }
    public "Profile Image": string;
    public "Paths": { [expertise: string]: PathTalent[] };
    public "Inventory": {
        weapons: WeaponModel[],
        armor: ArmorModel[],
        adventureGear: ItemModel[]
    };
    public "Spells": SpellModel[];
    public "Traditions": string[];
}

export interface Talent {
    name: string;
    description: object;
    action?: boolean;
    numberOfCasts?: number;
    modifiers?: {[key: string] : number};
}

export interface PathTalent extends Talent {
    path: string;
    level: number;
}

interface BackgroundType {
    rollValue: number;
    description: string;
}
interface AttributeModifier {
    attribute: "Strength" | "Agility" | "Intellect" | "Will" | "Perception" | "Insanity" | "Power" | "Damage" | "Corruption" | "Health";
    value: number;
    baseLineAttribute?: "Strength" | "Agility" | "Intellect" | "Will" | "Perception" | "Insanity" | "Power" | "Damage" | "Corruption";
}
