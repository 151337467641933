import React, {useEffect, useState} from "react";
import FullScreenDialog from "../../../../shared/components/dynamic-form/FullScreenDialog";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {NoteModel} from "../../../../shared/models/note.model";
import {updateNote} from "../../../redux/character/noteSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

function NoteDialog({open, email, id}: {open: boolean, email: string, id: string}) {
    const dispatch = useAppDispatch();
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(open);
    const {editingNote} = useAppSelector((state) => state.notes);
    const [noteModel, setNoteModel] = useState<NoteModel>(editingNote ?? new NoteModel().deserialize({identity: Math.random().toString(36).slice(2)}));
    const [stayOpen, setStayOpen] = useState<boolean>(true)

    const handleOk = (newNote: { [key: string]: any }) => {
        newNote.identity = editingNote?.identity ?? Math.random().toString(36).slice(2);
        setDialogOpen(!!editingNote ? false : stayOpen);
        if (!!editingNote) setNoteModel(new NoteModel());
        dispatch(updateNote({email: email, id: id, data: newNote}));
        handleCancel();
    };

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);

    const handleCancel = () => {
        setDialogOpen(false);
        if (!!editingNote) setNoteModel(new NoteModel().deserialize({
            identity: Math.random().toString(36).slice(2),
        }));
        dispatch({type: 'notes/openNote', payload: false});
    };

    return(
        <React.Fragment>
            <FullScreenDialog
                title={!!editingNote ? 'Edit Note' : 'Add Note'}
                saveButtonText="next"
                cancelButtonText="cancel"
                open={dialogOpen}
                onSubmit={handleOk}
                onCancel={handleCancel}
                initialValues={editingNote ?? noteModel}
                sections={[{title: '1'}, {title: '2'}]}
                fields={[
                    {
                        section: '1',
                        name: 'name',
                        type: 'text',
                        label: 'Name'
                    },{
                    section: '1',
                    name: 'noteType',
                    type: 'autocomplete',
                    label: 'Type',
                    options: [
                        {value: 'Ally', label: 'Ally'},
                        {value: 'Enemy', label: 'Enemy'},
                        {value: 'Organization', label: 'Organization'}
                    ]
                },{
                        section: '2',
                        name: 'note',
                        type: 'richText',
                        label: 'Note'
                    }]}
                validation={{
                    name: {
                        type: 'text', required: true, requiredMessage: 'Name is a required field.'
                    },
                }}
                additionalButtons={!!editingNote && <FormGroup>
                    <FormControlLabel control={<Switch
                        id={'stay-open'}
                        checked={stayOpen}
                        onChange={() => setStayOpen(!stayOpen)}
                        inputProps={{'aria-label': 'controlled'}}
                    />} label="Create Another"/>
                </FormGroup>}
            />
        </React.Fragment>
    )
}

export default NoteDialog;