import { TextareaAutosize } from "@mui/material";

export interface ITextArea {
    field: string;
    value: string;
    placeholder?: string;
    classNames?: string;
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
}
// TODO make this a rich text editor
function TextArea(props: ITextArea) {
    const handleChange = (e: any) => {
        props.dispatch({ type: 'update', payload: { [props.field]: e.target.value } })
    }
    const value = props.value;
    return <TextareaAutosize placeholder={props?.placeholder} name={props.field} value={value || ''} minRows={5} style={{ width: '100%' }}
        onChange={handleChange} />;
}

export default TextArea;
