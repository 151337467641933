import * as React from "react";
import {SpellModel} from "../../../../shared/models/spell.model";
import ExpandableSpellCard from "./ExpandableSpellCard";
import {Grid} from "@mui/material";
import {useAppSelector} from "../../../redux/hooks";

function SpellList(props: {
    spells: SpellModel[], power: number
}) {
    const spellsCasted = useAppSelector((state) => state.spells.spellsCasted);
    return (<>
        <Grid container spacing={2}>
            {props.spells.map((spell) => {
                return <Grid item> <ExpandableSpellCard spell={spell} power={props.power} casts={spellsCasted[spell.identity]}/></Grid>
            })}
        </Grid>
    </>);
}

export default SpellList;