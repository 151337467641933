import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Stack} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useState} from "react";

export interface Currency {
    bits: number;
    copper: number;
    silver: number;
    gold: number;
}

function CurrencyDialog({
                            open,
                            currency,
                            handleClose
                        }: { open: boolean, currency: Currency, handleClose: (currency?: Currency) => void }) {
    const [bits, setBits] = useState(currency?.bits ?? 0);
    const [copper, setCopper] = useState(currency?.copper ?? 0);
    const [silver, setSilver] = useState(currency?.silver ?? 0);
    const [gold, setGold] = useState(currency?.gold ?? 0);

    const reset= () => {
        setBits(0);
        setCopper(0);
        setSilver(0);
        setGold(0);
    };
    const save = () => {
        reset();
      handleClose({bits, copper, silver, gold});
    };

    return (
        <Dialog open={open} onClose={() => handleClose()}>
            <DialogTitle>Currency</DialogTitle>
            <DialogContent>
                <Stack direction={'row'} p={1}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="gold"
                        label="Gold"
                        type="number"
                        variant="outlined"
                        value={gold}
                        onChange={(event) => setGold(parseInt(event.target.value))}
                        sx={{marginRight: '10px'}}
                    />
                    <TextField
                        margin="dense"
                        id="silver"
                        label="Silver"
                        type="number"
                        variant="outlined"
                        sx={{mr: 1}}
                        value={silver}
                        onChange={(event) => setSilver(parseInt(event.target.value))}
                    />
                </Stack>
                <Stack direction={'row'} p={1}>
                    <TextField
                        margin="dense"
                        id="copper"
                        label="Copper"
                        type="number"
                        variant="outlined"
                        value={copper}
                        onChange={(event) => setCopper(parseInt(event.target.value))}
                        sx={{marginRight: '10px'}}
                    />
                    <TextField
                        margin="dense"
                        id="bits"
                        label="Bits"
                        type="number"
                        variant="outlined"
                        sx={{mr: 1}}
                        value={bits}
                        onChange={(event) => setBits(parseInt(event.target.value))}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    reset();
                    handleClose();
                }}>Cancel</Button>
                <Button onClick={save}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CurrencyDialog;