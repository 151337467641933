import {AbstractModel} from "./abstract.model";
import {ColumnDef} from "@tanstack/react-table";
import {ItemModel} from "./item.model";

export class SpellModel extends AbstractModel {
    public identity: string | undefined;
    public name: string = '';
    public tradition: string = '';
    public spellType: 'Attack' | 'Utility' | undefined;
    public rank: number = 0;
    public requirement: string = '';
    public target: string = '';
    public area: string = '';
    public duration: string = '';
    public description: string = '';
    public actionType: 'Action' | 'Triggered' | 'Sacrifice' | 'Permanence' | undefined;
    public additionalEffect: string = '';
    public attribute: 'Intellect' | 'Will' | undefined;
    public damage: string | undefined;
    public casts: number = 0;
}
