import React from "react";
import {Talent} from "../../Character.model";
import ListItem from "../../../../shared/components/ListItem";
import {Stack} from "@mui/material";

function WeaponAttack({ talent, active } : {talent: Talent, active : boolean}) {
    return (
        <ListItem active={active}>
            <div className="p-1">
                <div className="text-l font-medium">{talent.name}</div>
            </div>
            <React.Fragment>
                {talent?.modifiers !== null &&
                    <Stack direction={'row'} spacing={2}>
                        {talent?.modifiers && Object.keys(talent?.modifiers || {}).map((prop, index) => {
                            return (
                                <Stack key={prop} justifyContent={'center'} textAlign={'center'}>
                                    <span>{prop}</span>
                                    <span>{talent?.modifiers[prop] > 0 ? `+${talent?.modifiers[prop]}` : talent?.modifiers[prop]}</span>
                                </Stack>
                            )
                        })}
                    </Stack>
                }
            </React.Fragment>
        </ListItem>
    )
}

export default WeaponAttack;
