import {useEffect, useState} from 'react';
import './App.css';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Character} from './character/Character';
import {CharacterModel} from './character/Character.model';
import {Characters} from './characters/Characters';
import {LoginPage} from '../auth/LoginPage';
import {CharacterForm} from './character-form/CharacterForm'
import {updateCharacter} from "./redux/character/characterSlice";
import {Status} from "./redux/status";
import {fetchCharacters} from "./redux/characters/charactersSlice";
import {useAppDispatch, useAppSelector} from "./redux/hooks";

export function App() {
    const [characterList, setCharacterList] = useState<CharacterModel[]>([]);
    const {user, darkMode} = useAppSelector((state) => state.application);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const characterStatus = useAppSelector((state) => state.character.status);
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        }, components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'standard'
                }
            }, MuiFormControl: {
                defaultProps: {
                    variant: 'standard'
                }
            }
        }
    });
    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        }, components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'standard'
                }
            }, MuiFormControl: {
                defaultProps: {
                    variant: 'standard'
                }
            }
        }
    });

    const handleEdit = (character: CharacterModel) => {
        dispatch(updateCharacter(
            {
                email: user.email ?? '',
                id: character.identity,
                field: 'all',
                data: character
            }));
        dispatch(fetchCharacters(user.email ?? ''));
        if (characterStatus === Status.Succeeded) {
            navigate("/characters/" + character.identity);
        }
    }

    useEffect(() => {
        if (user) {
            return;
        } else {
            navigate("");
        }
    }, [user, navigate]);

    return (<div className={`${darkMode ? 'dark' : 'light'}`}>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <CssBaseline enableColorScheme/>
                <Routes>
                    <Route path="" element={<LoginPage/>}/>
                    <Route path="/create" element={<RequireAuth><CharacterForm handleCreate={handleEdit}
                                                                               character={new CharacterModel().deserialize({
                                                                                   identity: Math.random().toString(36).slice(2),
                                                                                   deleted: false
                                                                               })}/></RequireAuth>}/>
                    <Route path="/characters"
                           element={<RequireAuth>
                               <Characters characterList={characterList}
                                           setCharacterList={(characters: CharacterModel[]) => setCharacterList(characters)}/>
                           </RequireAuth>}/>
                    <Route path="/characters/:id" element={<RequireAuth><Character/></RequireAuth>}/>
                    <Route path="/characters/:id/edit"
                           element={<RequireAuth><CharacterForm handleCreate={handleEdit}/></RequireAuth>}/>
                </Routes>
            </ThemeProvider>
        </div>
    );
}

function RequireAuth({children}: { children: JSX.Element }) {
    const {user} = useAppSelector((state) => state.application);
    let location = useLocation();

    if (!user.email) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" state={{from: location}} replace/>;
    }

    return children;
}

export default App;
