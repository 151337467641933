import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "../status";

const initialState = {
    status: Status.Idle,
    globalSpells: new Array(),
    spells: new Array(),
    error: null,
    editingSpell: null,
    spellsCasted: {},
    openDialog: false,
    spellView: 'list'
}

export const spellSlice = createSlice({
    name: 'spells',
    initialState,
    reducers: {
        editSpell: (state, action) => {
            state.editingSpell = action.payload;
        },
        castSpell: (state, action) => {
            state.spellsCasted = {
                ...state.spellsCasted,
                [action.payload]: state.spellsCasted[action.payload] ?  state.spellsCasted[action.payload] + 1: 1
            };
        },
        uncastSpell: (state, action) => {
            state.spellsCasted = {
                ...state.spellsCasted,
                [action.payload]: state.spellsCasted[action.payload] ?  state.spellsCasted[action.payload] - 1: 1
            };
        },
        resetSpellsCasted: (state) => {
            state.spellsCasted = {};
        },
        openSpell: (state, action) => {
            state.openDialog = action.payload;
        },
        setSpellView: (state, action) => {
            state.spellView = action.payload;
            console.log(state, action);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createSpell.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(createSpell.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.spells = [...state.spells, action.payload];
            })
            .addCase(createSpell.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(getSpells.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(getSpells.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.spells = action.payload;
            })
            .addCase(getSpells.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(updateSpell.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(updateSpell.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                const index = state.spells.findIndex((spell) => spell.identity === action.payload.identity);
                state.spells[index] = action.payload;
            })
            .addCase(updateSpell.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(deleteSpell.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(deleteSpell.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.spells = action.payload;
            })
            .addCase(deleteSpell.rejected, (state, action) => {
                state.status = Status.Failed;
            })
    }
});

export const createSpell = createAsyncThunk('spells/create', async (payload: { email: string, id: string, data: any }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/create/${payload.id}`, {
        method: "POST",
        body: JSON.stringify({
            email: payload.email,
            spell: payload.data
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export const getSpells = createAsyncThunk('spells/get', async (payload: { id: string, email: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell?id=${payload.id}&email=${payload?.email}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
});

export const updateSpell = createAsyncThunk('spells/update', async (payload: { email: string, id: string, data: any }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/edit/${payload.id}`, {
        method: "POST",
        body: JSON.stringify({
            email: payload.email,
            spell: payload.data
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
});

export const deleteSpell = createAsyncThunk('spells/delete', async (payload: { email: string, id: string, data: any }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/delete/${payload.id}`, {
        method: "DELETE",
        body: JSON.stringify({
            email: payload.email,
            spell: payload.data
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
});

export const {openSpell} = spellSlice.actions;
export default spellSlice.reducer;