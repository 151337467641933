import {ItemModel} from "./item.model";

export interface WeaponModel extends ItemModel {
    damage: string;
    hands: number;
    properties?: {
        [name: string]: {
            description: string,
            banes: number,
            boons: number,
            reach?: number
        }
    };
    attribute: 'Strength' | 'Agility' | 'Finesse';
}


export const weaponProperties = {
    Cumbersome: {
        description: "When you attack with this weapon, you\n" +
            "make the attack roll with 1 bane.",
        banes: 0,
        boons: 0
    },
    Defensive: {
        description: "You add the number to your Defense while you wield this weapon.",
        banes: 0,
        boons: 0
    },
    Finesse: {
        description: "You can make a Strength attack roll or an Agility\n" +
            "attack roll for attacks made using this weapon.",
        banes: 0,
        boons: 0
    },
    Reach: {
        description: "You add the listed number to your reach when\n" +
            "you attack with the weapon.",
        banes: 0,
        boons: 0
    },
    Thrown: {
        description: "You can make a ranged attack with this weapon\n" +
            "by throwing it.",
        banes: 0,
        boons: 0
    },
    "Range(short)" : {
        description: "You can make a ranged attack with this weapon\n" +
            "by throwing it.",
        banes: 0,
        boons: 0,
        reach: 5
    },
    "Range(medium)" : {
        description: "You can make a ranged attack with this weapon\n" +
            "by throwing it.",
        banes: 0,
        boons: 0,
        reach: 20
    },
    "Range(long)" : {
        description: "You can make a ranged attack with this weapon\n" +
            "by throwing it.",
        banes: 0,
        boons: 0,
        reach: 100
    }
}
