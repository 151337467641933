import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "../status";

const initialState = {
    health: {
        current: 0,
        max: 0
    },
    afflictions: [],
    status: Status.Idle
}

export const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setHealth: (state, action) => {
            state.health.current = action.payload.current ?? 0;
            state.health.max = action.payload.max ?? 0;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(updateHealth.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(updateHealth.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.health.current = action.payload.CurrentHealth;
            })
            .addCase(updateHealth.rejected, (state, action) => {
                state.status = Status.Failed;
            })
    }
});

export const updateHealth = createAsyncThunk('status/updateHealth', async (payload: {
    email: string,
    id: string,
    data: any
}) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/character/edit/${payload.id}`, {
        method: "POST",
        body: JSON.stringify({email: payload.email, field: 'CurrentHealth', data: payload.data}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});
export default statusSlice.reducer;