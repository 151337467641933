import './Equipment.css';
import {Button, IconButton, Stack} from "@mui/material";
import {WeaponModel} from "../../../../../shared/models/weapon.model";
import {ArmorModel} from "../../../../../shared/models/armor.model";
import {AddEquipmentDialog} from "./AddEquipmentDialog";
import {itemColumns, ItemModel} from "../../../../../shared/models/item.model";
import {useParams} from "react-router-dom";
import {
    createEquipment,
    deleteEquipment,
    getEquipment,
    updateEquipment
} from "../../../../redux/character/equipmentSlice";
import React, {useEffect, useState} from "react";
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {MonetizationOn} from "@mui/icons-material";
import CurrencyDialog from "./CurrencyDialog";
import {updateCharacter} from "../../../../redux/character/characterSlice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import DataTable from "../../../../../shared/components/table/table";
import {castingTable} from "../../spells/spell-table/SpellTracker";

export interface IEquipment {
    Inventory: {
        weapons: WeaponModel[], armor: ArmorModel[], adventureGear: ItemModel[]
    };
    email: string
}

function Equipment(props: IEquipment) {
    //TODO need to be able to add a custom item that doesnt fit the types
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const equipment = useAppSelector((state) => state.equipment.equipment);
    const currency = useAppSelector((state) => state.character.character.Currency);
    const [openCurrency, setOpenCurrency] = useState(false);
    const [deleteItem, setDeleteItem] = useState({open: false, item: null});

    const onEquipmentAdd = (item?: ItemModel): void => {
        if (!item) {
            return;
        }
        // @ts-ignore
        dispatch(createEquipment({
            email: props.email, id: id, data: item
        }));
    }
    const handleClose = (currency?) => {
        setOpenCurrency(false);
        if (currency) {
            dispatch(updateCharacter({
                email: props.email, id: id, field: 'Currency', data: currency
            }))
        }
    };

    const onEquipmentEdit = (item: ItemModel): void => {
        dispatch(updateEquipment({
            email: props.email, id: id, data: item
        }));
    }

    useEffect(() => {
        dispatch(getEquipment({
            email: props.email, id: id
        }))
    }, [dispatch]);

    const columns: GridColDef[] = [{
        field: 'actions', headerName: '', width: 70, valueGetter: (params: GridValueGetterParams) => {
            return <div>Help</div>
        }
    }, {field: 'name', headerName: 'Name', width: 130}, {field: 'weight', headerName: 'Weight', width: 130}, {
        field: 'quantity', headerName: 'Quantity', type: 'number', width: 90,
    }, {
        field: 'cost', headerName: 'Cost', width: 160
    },];

    const row = (item: ItemModel, index: number, type: 'adventureGear' | 'weapons' | 'armor') => {
        return <tr key={index}>
            <td className='flex flex-row'>
                <AddEquipmentDialog handleClose={(data) => onEquipmentEdit(data)}
                                    equipment={item}/>
            </td>
        </tr>
    }

    const equipmentColumns = [
        {
            header: '',
            accessorFn: row => row.name ?? '---',
            id: 'cast',
            cell: ({row, getValue}) => {
                const item = row.original;
                return (
                  <Stack direction={'row'}>
                      <Button onClick={() => setDeleteItem({open: true, item: item})}>Delete</Button>
                      {
                          item.consumable && <Button onClick={() => {
                              dispatch(updateEquipment({
                                  email: props.email, id: id, data: {
                                      ...item,
                                      quantity: item.quantity - 1
                                  },
                                  field: 'quantity'
                              }));
                          }} disabled={item.quantity === 0}>Use</Button>
                      }
                  </Stack>
            )
            },
        },
        ...itemColumns
    ]

    return (<div>
        <div className="flex flex-row justify-between p-1">
            {deleteItem.open && (
                <DeleteDialog open={deleteItem.open} item={deleteItem.item} handleConfirm={(equipmentID) => {
                    dispatch(deleteEquipment({
                        email: props.email, id: id, equipmentID: equipmentID
                    }));
                    setDeleteItem({open: false, item: null});
                }} handleCancel={() => setDeleteItem({open: false, item: null})}/>)}
            <AddEquipmentDialog handleClose={onEquipmentAdd}/>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <>
                    <IconButton onClick={() => setOpenCurrency(true)}>
                        <MonetizationOn/>
                    </IconButton>
                    <CurrencyDialog open={openCurrency} currency={currency} handleClose={handleClose}/>
                </>
                <span className="flex-row text-[#ca9b52]">
                        <span>Gold: </span>
                        <span>{currency?.gold ?? 0}</span>
                    </span>
                <span className="flex-row text-[#9e9e9e]">
                        <span>Silver: </span>
                        <span>{currency?.silver ?? 0}</span>
                    </span>
                <span className="flex-row text-[#c88719]">
                        <span>Copper: </span>
                        <span>{currency?.copper ?? 0}</span>
                    </span>
                <span className="flex-row">
                        <span>Bits: </span>
                        <span>{currency?.bits ?? 0}</span>
                    </span>
            </Stack>
        </div>
        <DataTable<ItemModel> columns={equipmentColumns} data={equipment}/>
    </div>);
}

function DeleteDialog({
                          open,
                          item,
                          handleCancel,
                          handleConfirm
                      }: { open: boolean, item: ItemModel, handleCancel: () => void, handleConfirm: (identity: string) => void }) {
    return (
        <Dialog open={open} onBackdropClick={handleCancel}>
            <DialogTitle>Are you sure you want to delete {item.name}?</DialogTitle>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={() => {
                    handleConfirm(item.identity);
                }}>Continue</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Equipment;
