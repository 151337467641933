import Avatar from "@mui/material/Avatar";
import {ReactChild} from "react";
import ThemeToggle from "./ThemeToggle";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

function SideBar(props: { user: { email: string, picture: string, name: string }, children?: ReactChild | ReactChild[], }) {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col z-40 min-w-[100px] h-screen'>
            <div
                className='z-10 box-border bg-smoke-0 pt-4 pb-6 flex space-y-3 flex-col h-full min-h-screen w-[100px] border-0 border-r border-solid border-smoke-100'>
                <div className='flex flex-col items-center h-[100%]'>
                    {props?.children}
                    <ThemeToggle/>
                    <span className={'flex-1'}></span>
                    <div>
                        <Button onClick={() => {
                            firebase.auth().signOut();
                            navigate('/');
                        }}>Sign-out</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar;