import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {SpellModel} from "../../../../shared/models/spell.model";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import FullScreenDialog from "../../../../shared/components/dynamic-form/FullScreenDialog";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {NoteModel} from "../../../../shared/models/note.model";

interface ISpellDialog {
    open: boolean;
    handleClose: (value?: {}) => void;
    editing: boolean;
}

export function SpellDialog({open, handleClose, editing}: ISpellDialog) {
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(open);
    const {editingSpell} = useAppSelector((state) => state.spells);
    const [spellModel, setSpellModel] = useState<SpellModel>(editingSpell ?? new SpellModel().deserialize({
        spellType: 'Attack',
        attribute: 'Intellect',
        actionType: 'Action'
    }));
    const [stayOpen, setStayOpen] = useState<boolean>(true)
    const dispatch = useAppDispatch();
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleOk = (newSpell: { [key: string]: any }) => {
        newSpell.identity = editingSpell?.identity ?? Math.random().toString(36).slice(2);
        handleClose(newSpell);
        setDialogOpen(editing ? false : stayOpen);
        if (!editing) setSpellModel(new SpellModel());
    };

    useEffect(() => {
        setDialogOpen(open);
    }, [spellModel, open]);

    const handleCancel = () => {
        setDialogOpen(false);
        dispatch({type: 'spells/openSpell', payload: false});
        if (!editing) setSpellModel(new SpellModel().deserialize({
            identity: Math.random().toString(36).slice(2),
            spellType: 'Attack',
            attribute: 'Intellect'
        }));
        dispatch({type: 'spells/editSpell', payload: null});
    };

    return (
        <React.Fragment>
            <Button variant={'outlined'} onClick={handleClickOpen}>
                {editing ? 'Edit' : 'Add Spell'}
            </Button>
            <FullScreenDialog
                title={editing ? 'Edit Spell' : 'Add Spell'}
                saveButtonText="next"
                cancelButtonText="cancel"
                btnVariant="text"
                open={dialogOpen}
                onSubmit={handleOk}
                onCancel={handleCancel}
                initialValues={editingSpell ?? spellModel}
                sections={[{title: '1'}, {title: '2'}, {title: '3'}, {title: '4'}, {title: ''}]}
                fields={[{
                    section: '1',
                    name: 'name',
                    type: 'text',
                    label: 'Name',
                },
                    {
                        section: '1',
                        name: 'tradition',
                        type: 'text',
                        label: 'Tradition'
                    },
                    {
                        section: '1',
                        name: 'rank',
                        type: 'number',
                        label: 'Rank'
                    },
                    {
                        section: '2', name: 'spellType', type: 'select', label: 'Spell Type', options: [
                            {value: 'Attack', label: 'Attack'},
                            {value: 'Utility', label: 'Utility'},
                        ]
                    },
                    {
                        section: '2', name: 'attribute', type: 'select', label: 'Attribute', options: [
                            {value: 'Intellect', label: 'Intellect'},
                            {value: 'Will', label: 'Will'},
                        ]
                    },
                    {
                        section: '2', name: 'actionType', type: 'select', label: 'Action Type', options: [
                            {value: 'Action', label: 'Action'},
                            {value: 'Triggered', label: 'Triggered'},
                            {value: 'Sacrifice', label: 'Sacrifice'},
                            {value: 'Permanence', label: 'Permanence'},
                        ]
                    },
                    {
                        section: '3',
                        name: 'target',
                        type: 'text',
                        label: 'Target'
                    },
                    {
                        section: '3',
                        name: 'area',
                        type: 'text',
                        label: 'Area'
                    },
                    {
                        section: '3',
                        name: 'damage',
                        type: 'text',
                        label: 'Damage'
                    },
                    {
                        section: '',
                        name: 'requirement',
                        type: 'text',
                        label: 'Requirement'
                    },
                    {
                        section: '',
                        name: 'duration',
                        type: 'text',
                        label: 'Duration'
                    },
                    {
                        section: '4',
                        name: 'description',
                        type: 'richText',
                        label: 'Description'
                    },
                    {
                        section: '',
                        name: 'additionalEffect',
                        type: 'text',
                        label: 'Additional Effect'
                    }]}
                validation={{
                    name: {
                        type: 'text', required: true, requiredMessage: 'Name is a required field.'
                    },
                }}
                additionalButtons={!editing && <FormGroup>
                    <FormControlLabel control={<Switch
                        id={'stay-open'}
                        checked={stayOpen}
                        onChange={() => setStayOpen(!stayOpen)}
                        inputProps={{'aria-label': 'controlled'}}
                    />} label="Create Another"/>
                </FormGroup>}
            />
        </React.Fragment>
    );
}

// TODO add a conditional field based on another field