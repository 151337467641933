import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import './CharacterCard.css';
import {Button, Typography} from "@mui/material";
import {fetchCharacter} from "../redux/character/characterSlice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useAppDispatch} from "../redux/hooks";

export interface card {
    id: string;
    name: string;
    handleDelete: (id: string) => void;
    email: string;
}

function CharacterCard(card: card) {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [removeCharacter, setRemoveCharacter] = useState(false);

    return (
        <div className="shadow-md flex flex-col m-1 border dark:border-sky-700 rounded h-[125px] dark:bg-sky-800" onClick={() => {
            navigate("/characters/" + card.id);
            localStorage.removeItem('appState');
            dispatch(fetchCharacter({email: card.email, id: card.id}));
        }}>
            <Dialog open={removeCharacter} onBackdropClick={() => setRemoveCharacter(false)}>
                <DialogTitle>Are you sure you want to delete {card.name}?</DialogTitle>
                <DialogActions>
                    <Button onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setRemoveCharacter(false)
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        card.handleDelete(card.id);
                        setRemoveCharacter(false);
                    }}>Continue</Button>
                </DialogActions>
            </Dialog>
            <div className="p-1 flex flex-col items-center">
                <Typography variant={'h4'}>
                    {card.name}
                </Typography>
            </div>
            <div className="flex flex-row h-[100%] relative justify-end">
                <div className="absolute bottom-0">
                    <Button onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setRemoveCharacter(true);
                    }}>Delete</Button>
                </div>
            </div>
        </div>
    );
}

export default CharacterCard
