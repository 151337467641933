import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "../status";

const initialState = {
    status: Status.Idle,
    notes: new Array(),
    openDialog: false,
    error: null,
    editingNote: null
}

export const noteSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        editNote: (state, action) => {
            state.editingNote = action.payload;
        },
        openNote: (state, action) => {
            state.openDialog = action.payload;
            console.log(state, action);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createNote.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(createNote.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.notes = [...state.notes, action.payload];
            })
            .addCase(createNote.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(getNotes.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(getNotes.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.notes = action.payload;
            })
            .addCase(getNotes.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(updateNote.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(updateNote.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                const index = state.notes.findIndex((note) => note.identity === action.payload.identity);
                state.notes[index] = action.payload;
            })
            .addCase(updateNote.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(deleteNote.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(deleteNote.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                const index = state.notes.findIndex((note) => note.identity === action.payload);
                state.notes.splice(index, 1);
            })
            .addCase(deleteNote.rejected, (state, action) => {
                state.status = Status.Failed;
            })
    }
});

export const createNote = createAsyncThunk('notes/create', async (payload: { email: string, id: string, data: any }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/note/create/${payload.id}`, {
        method: "POST",
        body: JSON.stringify({
            email: payload.email,
            note: payload.data
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export const getNotes = createAsyncThunk('notes/get', async (payload: { id: string, email: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/note?id=${payload.id}&email=${payload?.email}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
});

export const updateNote = createAsyncThunk('equipment/update', async (payload: { email: string, id: string, data: any, field?: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/note/${payload.id}/update`, {
        method: "POST",
        body: JSON.stringify({email: payload.email, note: payload.data, field: payload?.field}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export const deleteNote = createAsyncThunk('spells/delete', async (payload: { email: string, id: string, noteID: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/note/delete/${payload.id}`, {
        method: "DELETE",
        body: JSON.stringify({
            email: payload.email,
            noteID: payload.noteID
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
});

export const {openNote} = noteSlice.actions;

export default noteSlice.reducer;