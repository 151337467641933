import { WeaponModel } from "../../../../../shared/models/weapon.model";
import ListItem from "../../../../../shared/components/ListItem";
import React from "react";

function WeaponAttack(props: { weapon: WeaponModel, agility: number, strength: number, active: boolean }) {
    const weapon = props.weapon;
    const getAttackModifier = (properties: {
        [name: string]: {
            description: string,
            banes: number,
            boons: number,
            reach?: number
        }
    }): string => {
        let attackModifier = `${props.agility - 10}`;
        const keys = Object.keys(properties);
        if (keys.includes('Finesse')) {
            attackModifier = `${props.strength - 10} || ${props.agility - 10}`
        }
        return attackModifier
    }
    return (
        <ListItem active={props.active}>
            <div className="flex-1 p-1">
                <div className="text-l font-medium">{weapon.name}</div>
                <span className="font-light">{weapon.hands === 1 ? 'One-Handed' : 'Two-Handed'}</span>
            </div>
            <React.Fragment>
                {weapon?.properties !== null &&
                    <div className="p-1 m-1 rounded-full flex flex-col justify-center items-center">
                        <span className="text-xs font-light">PROPS</span>
                        {weapon?.properties && Object.keys(weapon?.properties || {}).map((prop, index) => <span key={index}>{prop}</span>)}
                    </div>
                }
            </React.Fragment>
            <div className="p-1 m-1 rounded-full flex flex-col justify-center items-center">
                <span className="text-xs font-light">DMG</span>
                <span>{weapon.damage || 0}</span>
            </div>
            <div className="p-1 m-1 rounded-full flex flex-col justify-center items-center">
                <span className="text-xs font-light">MOD</span>
                {getAttackModifier(weapon?.properties || {})}
            </div>
        </ListItem>
    )
}

export default WeaponAttack;
