import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {getNotes} from "../../../redux/character/noteSlice";
import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import RichTextEditor from "../../../../shared/components/rich-text-editor/RichTextEditor";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

function Notes({email}: { email: string }) {
    const {notes, editingNote} = useAppSelector((state) => state.notes);
    const {id} = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getNotes({id: id, email: email}));
    }, []);

    return (
        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
            {notes.map((note, index) => (
                <Grid xs={2} sm={4} md={4} key={index} item>
                    <Card key={note.identity} variant={'outlined'}>
                        <Stack p={1}>
                            <Typography variant={'h5'}>{note.name}</Typography>
                            <Typography sx={{marginLeft: '3px'}}
                                        variant={'subtitle1'}>{note.noteType?.label}</Typography>
                        </Stack>
                        <CardContent>
                            <RichTextEditor readOnly={true} state={note.note}/>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export default Notes;