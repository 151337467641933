import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextareaAutosize,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import TalentLabel from "../../../../../shared/components/TalentLabel";
import { PathTalent } from "../../../Character.model";
import ScrollableContainer from "../../../../../shared/components/ScrollableContainer";
export interface IPath {
    talents: { [key: string]: PathTalent[] };
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
    editable?: boolean;
}
function Path(props: IPath) {
    const [talents, setTalents] = useState<{ [expertise: string]: PathTalent[] }>(props.talents ?? {});
    const [expertise, setExpertise] = useState('Novice');
    const [path, setPath] = useState('');
    const [level, setLevel] = useState(1);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [adding, setAdding] = useState<boolean>(false)
    const [removing, setRemoving] = useState<boolean>(false)

    const onAdd = () => {
        const pathKey = expertise + ' ' + path;
        if (!talents[pathKey]) {
            talents[pathKey] = [{ path, name, description, level }];
        } else {
            talents[pathKey].push({ path, name, description, level })
        }
        const newMap = { ...talents };
        setTalents(newMap);
        props.dispatch({ type: 'update', payload: { 'Paths': talents } });
        setDescription('');
        setName('');
    }
    const onRemove = (key: string, index: number) => {
        talents[key].splice(index, 1);
        if (talents[key].length === 0) {
            delete talents[key];
        }
        const newMap = { ...talents };
        setTalents(newMap);
        props.dispatch({ type: 'update', payload: { 'Novice Path': talents } });
    }

    return (
        <div>
            <div>
                {adding ? <Toolbar>
                    <FormControl>
                        <InputLabel id="expertise-select-label">Expertise</InputLabel>
                        <Select
                            labelId="expertise-select-label"
                            id="expertise-select"
                            value={expertise}
                            label="Expertise"
                            onChange={(event: SelectChangeEvent) => setExpertise((event.target as HTMLInputElement).value)}
                        >
                            <MenuItem value={'Novice'}>Novice</MenuItem>
                            <MenuItem value={'Expert'}>Expert</MenuItem>
                            <MenuItem value={'Master'}>Master</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <TextField
                            label={'Level'}
                            value={level}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLevel(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} placeholder={'Level'}
                            style={{ marginRight: '5px' }} />
                    </FormControl>
                    <TextField
                        value={path}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPath((event.target as HTMLInputElement).value)}
                        label={'Path'} style={{ marginRight: '5px' }} />
                    <TextField
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName((event.target as HTMLInputElement).value)}
                        label={'Talent'} style={{ marginRight: '5px' }} />
                    <TextareaAutosize placeholder={'Description'}
                        value={description}
                        minRows={3}
                        style={{ maxHeight: 56, height: 56 }}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setDescription((event.target as HTMLTextAreaElement).value)} />
                    <Button onClick={onAdd}>Add</Button>
                    <Button onClick={() => {
                        setAdding(!adding);
                        setRemoving(!adding)
                    }}>Cancel</Button>
                </Toolbar> : props.editable ? <div>
                    <Button onClick={() => setAdding(!adding)}>Add Talents</Button>
                    <Button onClick={() => setRemoving(!removing)}>{removing ? "Cancel Remove" : "Remove Talents"}</Button>
                </div> : ''}
            </div>
            <ScrollableContainer>
                {Object.keys(talents).map((key: string, index) => {
                    return <div className={'talent'} key={key}>
                        <Typography variant={'h6'}>{talents[key][0]?.path}</Typography>
                        <div>
                            {talents[key].map((talent: PathTalent, index: number) => {
                                return (
                                    <div key={index}>
                                        {TalentLabel(talent.name, talent.description)}
                                        {props.editable ? <Button onClick={() => onRemove(key, index)}>Remove</Button> : ''}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                })}
            </ScrollableContainer>
        </div>
    )
}

export default Path;
