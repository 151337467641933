import {useEffect, useState} from "react";
import {SpellModel} from "../../../../../shared/models/spell.model";
import SpellsFilter from "./SpellsFilter";
import Button from "@mui/material/Button";
import {Divider, Stack} from "@mui/material";
import {useSelector} from "react-redux";

export interface IManageSpells {
    power: number;
    learnSpell: (spell: SpellModel) => void;
    removeSpell: (spell: SpellModel) => void;
    editSpell: (spell: SpellModel) => void;
}

function ManageSpells(props: IManageSpells) {
    const learnedSpells = useSelector((state) => state.spells.spells);;
    const [filteredSpells, setFilteredSpells] = useState<SpellModel[]>(learnedSpells);
    const learnSpell = (spell: SpellModel) => {
        if (spellLearned(spell)) {
            props.removeSpell(spell);
        } else {
            props.learnSpell(spell);
        }
    }
    const spellLearned = (spell: SpellModel): boolean => {
        return !!learnedSpells.find((learnedSpell) => learnedSpell.identity === spell.identity);
    }

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/spells`)
    //         .then(res => res.json())
    //         .then(response => {
    //             const spells = response.map((spell: object) => new SpellModel().deserialize(spell));
    //             setSpellList(spells);
    //             setFilteredSpells(spells)
    //         });
    // }, []);
    const filterSpells = (spells: SpellModel[]): void => {
        setFilteredSpells(spells);
    }
    return (
        <div>
            <div
                className="transition-all absolute top-0 right-0 z-50 h-screen w-[30%] dark:bg-gray-800 bg-gray-100 shadow sm:rounded-lg z-[1100]">
                <SpellsFilter spellList={learnedSpells} filterSpells={filterSpells}/>
                <Stack direction={"column"} divider={<Divider orientation="horizontal" flexItem />}>
                    {filteredSpells.map((spell, index) => {
                        return (
                            <Stack direction={'row'} key={spell.identity} p={1} justifyContent={'space-between'}>
                                <span>{spell.name} ({spell.tradition} Rank: {spell.rank})</span>
                                <span>
                                    <Button
                                        onClick={() => learnSpell(spell)}>
                                    {spellLearned(spell) ? 'Remove' : 'Learn'}
                                </Button>
                                <Button onClick={() => props.editSpell(spell)}>Edit</Button>
                                </span>
                            </Stack>
                        )
                    })}
                </Stack>
            </div>
        </div>
    )
}

export default ManageSpells;