import * as React from "react";
import {Paper, Slider, Stack, Typography} from "@mui/material";

function AttributeForm({character, changeHandle}) {
    const handleChange = (value, field) => {
        changeHandle(field, value);
    }
    const attributeFields: ("Strength" | "Agility" | "Intellect" | "Will" | "Speed" | "Defense")[] = ["Strength", "Agility", "Intellect", "Will", "Speed", "Defense"];
    const characteristics: ("Perception" | "Insanity" | "Power" | "Damage" | "Corruption" | "Size" | "Health")[]
        = ["Perception", "Insanity", "Power", "Damage", "Corruption", "Size", "Health"];

    return (<Stack direction={'row'} spacing={2}>
        <Paper sx={{flex: 1}}>
            <Stack flex={1}>
                {attributeFields.map((field) => {
                    return (
                        <span className={"p-1"} key={field}>
                            <Typography id={`${field}-slider`} gutterBottom>
                                {field}: {character[field] ?? 0}
                            </Typography>
                            <Slider
                                id={field}
                                value={character[field] ?? 0}
                                min={0}
                                step={1}
                                max={20}
                                onChange={(event, value, activeThumb) => handleChange(value, field)}
                                valueLabelDisplay="auto"
                                aria-labelledby={`${field}-slider`}
                            />
                        </span>
                    )
                })}
            </Stack>
        </Paper>
        <Paper sx={{flex: 1}}>
            <Stack flex={1}>
                {characteristics.map((field) => {
                    return (
                        <span className={"p-1"} key={field}>
                            <Typography id={`${field}-slider`} gutterBottom>
                                {field}: {character[field] ?? 0}
                            </Typography>
                            <Slider
                                id={field}
                                value={character[field] ?? 0}
                                min={0}
                                step={1}
                                onChange={(event, value, activeThumb) => handleChange(value, field)}
                                valueLabelDisplay="auto"
                                aria-labelledby={`${field}-slider`}
                            />
                        </span>
                    )
                })}
            </Stack>
        </Paper>
    </Stack>);

}

export default AttributeForm;