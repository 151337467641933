import { Typography } from "@mui/material";
import TextInput from "../../../shared/components/TextInput";
import './Description.css';
import TextArea from "../../../shared/components/TextArea";
import DescriptionDialog from "./DescriptionDialog";
import { CharacterModel } from "../Character.model";
export interface IDescription {
    character: CharacterModel;
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
    editable?: boolean;
}
function Description(props: IDescription) {
    const APPEARANCE: ('Gender' | 'Height' | 'Weight' | 'Age')[] = ['Gender', 'Height', 'Weight', 'Age'];
    const BACKGROUND = [
        "Origin",
        "Childhood",
        "Life Experiences",
        "Major Setback",
        "Minor Setback",
        "Major Windfall",
        "Minor Windfall",
        "Strange Event"
    ];
    const PERSONALITY = [
        "Personality",
        "Relationships",
        "Desires",
        "Fears",
        "Obligations",
        "Morality",
        "Religion",
        "Piety",
        "Quirk",
        "Hatred",
        "Odd Habit"
    ];

    const handleBackground = (value?: any) => {
        const background = { ...props.character.Background, [value.property]: value };
        props.dispatch({ type: 'update', payload: { 'Background': background } });
    }

    const handlePersonality = (value?: any) => {
        const personality = { ...props.character.Personality, [value.property]: value };
        props.dispatch({ type: 'update', payload: { 'Personality': personality } });
    }

    return <div>
        <div>
            <div className="border-b-2 border-[#2B50AA]">
                <Typography>APPEARANCE</Typography>
            </div>
            <div className="flex flex-row justify-evenly p-1">
                {APPEARANCE.map((field) => {
                    return props.editable ? (
                        <span className={'description-appearance'} key={field}>
                            <Typography variant={'subtitle1'}>{field}:</Typography>
                            <TextInput field={field} value={props.character[field]} dispatch={props.dispatch} />
                            <Typography>{field === 'Height' ? 'Inches' : field === 'Weight' ? 'lbs' : ''}</Typography>
                        </span>
                    ) : (
                        <div className="flex flex-col justify-center items-center" key={field}>
                            <span className="text-inherit font-bold">
                                <Typography variant={'subtitle2'}>{field}</Typography>
                            </span>
                            <Typography className='flex flex-col justify-center items-center text-inherit font-bold speed-input'>
                                {props.character[field]}
                            </Typography>
                        </div>
                    );
                })}
            </div>
            <div className="flex flex-col">
                {props.editable && <span className={'description-characteristic'}>
                    <Typography variant={'subtitle1'}>Description:</Typography>
                    <TextArea field={'AppearanceDescription'} value={props.character.AppearanceDescription} dispatch={props.dispatch} />
                </span>}
                {propLabel('Description', props.character.AppearanceDescription)}
            </div>
        </div>
        <div>
            <div className="border-b-2 border-[#2B50AA]">
                <Typography>BACKGROUND</Typography>
            </div>
            {props.editable && <div className="flex flex-row justify-end">
                <DescriptionDialog type={'Background'} editing={false} properties={BACKGROUND}
                    handleClose={handleBackground} />
            </div>}
            <div className={'description-container'}>
                {props.character.Background && Object.keys(props.character.Background).map((background: string, index) => {
                    // @ts-ignore
                    return propLabel(background, props.character.Background[background].description);
                })}
            </div>
        </div>
        <div>
            <div className="border-b-2 border-[#2B50AA]">
                <Typography>PERSONALITY</Typography>
            </div>
            {props.editable && <div className="flex flex-row justify-end">
                <DescriptionDialog type={'Personality'} editing={false} properties={PERSONALITY}
                    handleClose={handlePersonality} />
            </div>}
            {props.character.Personality && Object.keys(props.character.Personality).map((personality, index) => {
                // @ts-ignore
                return propLabel(personality, props.character.Personality[personality].description);
            })}
        </div>
    </div>
}

function propLabel(field: string, description: string) {
    return (
        <span className="flex flex-col" key={field}>
            <span className="text-sm font-medium">{field}</span>
            <span className="text-sm">{description}</span>
        </span>
    )
}
export default Description;
