import {ReactChild} from "react";

function ScrollableContainer({children}: { children: ReactChild | ReactChild[]}) {
    // TODO maybe make the offset my dynamic by getting the height of the rest of the document
    return (
        <div className="overflow-auto h-[90%]">
            {children}
        </div>
    );
}

export default ScrollableContainer;