import React from "react";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {weaponProperties} from "../../../../../shared/models/weapon.model";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import './Equipment.css'
import {ItemModel} from "../../../../../shared/models/item.model";

export function AddEquipmentDialog(props: { handleClose: (value: ItemModel) => void, equipment?: ItemModel }) {
    const item = props.equipment;
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState(props.equipment?.type || 'adventureGear');
    const [name, setName] = React.useState(item?.name || '');
    const [availability, setAvailability] = React.useState<'Common' | 'Uncommon' | 'Rare' | 'Exotic'>(item?.availability || 'Common');
    const [damage, setDamage] = React.useState(item?.damage || '');
    const [hands, setHands] = React.useState(item?.hands || 1);
    const [properties, setProperties] = React.useState(item?.properties ? Array.from(Object.keys(item?.properties)) : '');
    const [weight, setWeight] = React.useState(item?.weight || 0);
    const [defense, setDefense] = React.useState(item?.defense || 0);
    const [quantity, setQuantity] = React.useState(item?.quantity || 1);
    const [price, setPrice] = React.useState(item?.price || 0);
    const [consumable, setConsumable] = React.useState(item?.consumable || false);
    const [attribute, setAttribute] = React.useState<'Strength' | 'Agility' | 'Finesse'>(item?.attribute || 'Strength');
    const [equipped, setEquipped] = React.useState(item?.equipped || false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOk = () => {
        props.handleClose({
            identity: props?.equipment?.identity ?? Math.random().toString(36).slice(2),
            type: type,
            active: true,
            name,
            damage,
            availability,
            hands,
            //TODO fix this by putting in types
            // @ts-ignore
            properties: {[properties]: weaponProperties[properties]},
            weight,
            quantity,
            price,
            consumable,
            equipped
        });
        if (props.equipment) {
            setOpen(false);
        } else {
            resetForm();
        }
    };

    const handleCancel = () => {
        setOpen(false);
        resetForm();
    };

    const resetForm = () => {
        setName('');
        setDamage('')
        setHands(1);
        setProperties('');
        setWeight(0);
        setPrice(0);
        setQuantity(1);
        setAvailability('Common');
        setDefense(0);
    }

    const renderSwitch = (type: string) => {
        switch (type) {
            case 'weapons':
                return <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Weapon Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName((event.target as HTMLInputElement).value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="damage"
                        label="Damage"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={damage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDamage((event.target as HTMLInputElement).value)}
                    />
                    <FormControl>
                        <InputLabel id="hands-select-label">Hands</InputLabel>
                        <Select
                            labelId="hands-select-label"
                            id="hands-select"
                            value={hands.toString()}
                            label="Hands"
                            onChange={(event: SelectChangeEvent) =>
                                setHands(parseInt((event.target as HTMLInputElement).value))}
                        >
                            <MenuItem value={1}>1-Handed</MenuItem>
                            <MenuItem value={2}>2-handed</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="properties-select-label">Properties</InputLabel>
                        <Select
                            labelId="properties-select-label"
                            id="properties-select"
                            value={properties}
                            label="Properties"
                            onChange={(event: SelectChangeEvent) =>
                                setProperties((event.target as HTMLInputElement).value)}
                        >
                            {Object.keys(weaponProperties).map((property) => <MenuItem key={property}
                                                                                       value={property}>{property}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="attribute-select-label">Attribute</InputLabel>
                        <Select
                            labelId="attribute-select-label"
                            id="attribute-select"
                            value={attribute}
                            label="Attribute"
                            onChange={(event: SelectChangeEvent) =>
                                setAttribute((event.target as HTMLInputElement).value as 'Strength' | 'Agility' | 'Finesse')}
                        >
                            <MenuItem value={'Strength'}>Strength</MenuItem>
                            <MenuItem value={'Agility'}>Agility</MenuItem>
                            <MenuItem value={'Finesse'}>Finesse</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <TextField
                            value={weight}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWeight(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Weight'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    <FormControl>
                        <TextField
                            value={quantity}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuantity(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Quantity'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    {/*TODO split into the four type bits, copper, silver gold*/}
                    <FormControl>
                        <TextField
                            value={price}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrice(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Price'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    <div className="flex flex-col justify-center items-center">
                        <span className="p-1">Equipped</span>
                        <input type="checkbox" onChange={(event) => setEquipped(event.target.checked)}
                               className="checked:bg-blue-500"/>
                    </div>
                </div>
            case 'armor':
                return <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Armor Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName((event.target as HTMLInputElement).value)}
                    />
                    <FormControl>
                        <TextField
                            value={defense}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDefense(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Defense'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    <FormControl>
                        <TextField
                            value={weight}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWeight(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Weight'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    <FormControl>
                        <TextField
                            value={quantity}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuantity(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Quantity'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    {/*TODO split into the four type bits, copper, silver gold*/}
                    <FormControl>
                        <TextField
                            value={price}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrice(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Price'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                </div>
            default:
                return <div>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Adventure Gear Name"
                            type="text"
                            fullWidth
                            value={name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName((event.target as HTMLInputElement).value)}
                        />
                        <TextField
                            value={weight}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWeight(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Weight'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    <FormControl>
                        <TextField
                            value={quantity}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuantity(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Quantity'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                    {/*TODO split into the four type bits, copper, silver gold*/}
                    <FormControl>
                        <TextField
                            value={price}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrice(parseInt((event.target as HTMLInputElement).value))}
                            type={'number'} label={'Price'}
                            style={{marginRight: '5px'}}/>
                    </FormControl>
                </div>
        }
    }

    return (
        <div>
            {!props.equipment && <Button variant="contained" onClick={handleClickOpen}>
                Add Equipment
            </Button>}
            {props.equipment && <Button onClick={handleClickOpen}>
                Edit
            </Button>}
            <Dialog open={open}>
                <DialogTitle>Enter Equipment information.</DialogTitle>
                <DialogContent className="flex flex-col">
                    <FormControl>
                        <InputLabel id="type-select-label">Equipment Type</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            value={type}
                            label="Expertise"
                            onChange={(event: SelectChangeEvent) => {
                                setType((event.target as HTMLInputElement).value);
                                resetForm();
                            }}
                        >
                            <MenuItem value={'adventureGear'}>Adventure Gear</MenuItem>
                            <MenuItem value={'weapons'}>Weapon</MenuItem>
                            <MenuItem value={'armor'}>Armor</MenuItem>
                        </Select>
                    </FormControl>
                    {renderSwitch(type)}
                    <div className="flex flex-col justify-center items-center">
                        <span className="p-1">Consumable</span>
                        <input type="checkbox" onChange={(event) => setConsumable(event.target.checked)}
                               className="checked:bg-blue-500"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color={'inherit'}>Cancel</Button>
                    <Button onClick={handleOk} color={'primary'}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

