import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import React, {ReactElement} from "react";
import Button from "@mui/material/Button";
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import {useFormik} from "formik";
import RichTextEditor from "../rich-text-editor/RichTextEditor";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface FullScreenDialogProps {
    title: string;
    open: boolean;
    fields: Field[];
    initialValues: { [key: string]: any };
    validation: { [key: string]: any };
    onSubmit: (values: { [key: string]: any }) => void,
    onCancel: () => void,
    additionalButtons: Element | boolean,
    formDirection?: 'row' | 'col';
    sections: { title: string, showTitle?: boolean }[];
    saveButtonText?: string;
    cancelButtonText?: string;
}

function FullScreenDialog({
                              title,
                              open,
                              fields,
                              initialValues,
                              onSubmit,
                              additionalButtons,
                              onCancel,
                              formDirection = 'row',
                              sections = [],
                              validation = {}
                          }: FullScreenDialogProps) {
    const generateInitialValues = () => {
        let initialValue: { [key: string]: any } = {};
        if (fields) {
            fields.forEach((field: any) => {
                if (field.type !== 'label' && field.type !== 'element') {
                    initialValue[field.name] = initialValues && initialValues.hasOwnProperty(field.name) && initialValues[field.name] !== null ? initialValues[field.name] : '';
                }
            });
        }
        return initialValue;
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: null,
        onSubmit: () => {
            onSubmit(formik.values);
        },
    });
    const fieldRenderer = (fieldMeta: Field, field_id: number, sectionId: number, isRequired: boolean): ReactElement => {
        // Making sure the variant provided to the inputs is a valid one
        if (!fieldMeta.variant || !['outlined', 'filled', 'standard'].includes(fieldMeta.variant)) {
            fieldMeta.variant = 'outlined';
        }
        switch (fieldMeta.type) {
            case 'text':
                return (
                    <TextField
                        required={isRequired}
                        key={'text_' + field_id + '_' + sectionId}
                        type={fieldMeta.type}
                        disabled={fieldMeta.disabled}
                        name={fieldMeta.name}
                        label={fieldMeta.label}
                        value={formik.values[fieldMeta.name]}
                        onChange={formik.handleChange}
                        error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                        helperText={formik.touched[fieldMeta.name] && formik.errors[fieldMeta.name]}
                        InputProps={fieldMeta.inputProps ? fieldMeta.inputProps : {}}
                        variant={"outlined"}
                    />
                );
            case 'number':
                return (
                    <TextField
                        required={isRequired}
                        key={'text_' + field_id + '_' + sectionId}
                        type="number"
                        disabled={fieldMeta.disabled}
                        name={fieldMeta.name}
                        label={fieldMeta.label}
                        value={formik.values[fieldMeta.name]}
                        onChange={formik.handleChange}
                        error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                        helperText={formik.touched[fieldMeta.name] && formik.errors[fieldMeta.name]}
                        InputProps={fieldMeta.inputProps ? fieldMeta.inputProps : {}}
                        variant={"outlined"}
                    />
                );
            case 'hidden':
                return (
                    <TextField
                        className='hidden'
                        key={'text_' + field_id + '_' + sectionId}
                        disabled={true}
                        name={fieldMeta.name}
                        value={formik.values[fieldMeta.name]}
                        onChange={formik.handleChange}
                        error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                        InputProps={fieldMeta.inputProps ? fieldMeta.inputProps : {}}
                        variant={"outlined"}
                    />
                );
            case 'select':
                return (
                    <FormControl
                        error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                        key={'select_' + field_id + '_' + sectionId}
                        disabled={fieldMeta.disabled}
                        required={isRequired}
                        variant={"outlined"}
                    >
                        <InputLabel id={'select_label_' + field_id + '_' + sectionId}>{fieldMeta.label}</InputLabel>
                        <Select
                            labelId={'select_label_' + field_id + '_' + sectionId}
                            name={fieldMeta.name}
                            label={fieldMeta.label}
                            multiple={fieldMeta.multiple}
                            value={formik.values[fieldMeta.name]}
                            onChange={formik.handleChange}
                        >
                            {
                                fieldMeta?.options?.map((opt, index) => {
                                    return (
                                        <MenuItem value={opt.value} key={'option_' + field_id + '_' + index}>
                                            {fieldMeta.enableCheckBox && <Checkbox
                                                checked={formik.values[fieldMeta.name].indexOf(opt.value) > -1}/>}
                                            {opt.label}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        {formik.touched[fieldMeta.name] &&
                            <FormHelperText>{formik.errors[fieldMeta.name]}</FormHelperText>}
                    </FormControl>
                );
            case 'multi-select':
                return (
                    <FormControl
                        error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                        key={'select_' + field_id + '_' + sectionId}
                        disabled={fieldMeta.disabled}
                        required={isRequired}
                        variant={"outlined"}
                    >
                        <Autocomplete
                            multiple
                            key={'multi-select_' + field_id + '_' + sectionId}
                            options={fieldMeta.options ?? []}
                            value={formik.values[fieldMeta.name]}
                            onChange={(event, value) => {
                                formik.setFieldTouched(fieldMeta.name, true);
                                formik.setFieldValue(fieldMeta.name, value);
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option: { value: any, label: string }, value: any) => option.value === value.value}
                            renderOption={(props: any, opt, index) => (
                                <div>
                                    <Checkbox
                                        style={{marginRight: 8}}
                                        checked={formik.values[fieldMeta.name].findIndex((option: { value: any, label: string }) => option.value === props.value) > -1}
                                    />
                                    {props.label}
                                </div>
                            )}
                            renderInput={(params) => <TextField
                                {...params}
                                id={'select_label_' + field_id + '_' + sectionId}
                                label={fieldMeta.label}
                                required={isRequired}
                                error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                            />}
                            renderTags={(tagValue, getTagProps) => {
                                return tagValue.map((option, index) => (
                                    <Chip {...getTagProps({index})} label={option.label} variant="outlined"/>
                                ));
                            }}
                        />
                        {formik.touched[fieldMeta.name] &&
                            <FormHelperText>{formik.errors[fieldMeta.name]}</FormHelperText>}
                    </FormControl>
                );
            case 'checkbox':
                return (
                    <FormControl
                        error={formik.touched[fieldMeta.name] && Boolean(formik.errors[fieldMeta.name])}
                        key={'checkbox_' + field_id + '_' + sectionId}
                        disabled={fieldMeta.disabled}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox checked={formik.values[fieldMeta.name]}
                                          onChange={fieldMeta.onChange ? (event) => {
                                              fieldMeta?.onChange?.(event, formik);
                                          } : formik.handleChange}
                                          name={fieldMeta.name}
                                          classes={{root: 'custom-checkbox-root'}}
                                          disabled={fieldMeta.disabled}
                                />
                            }
                            label={fieldMeta.label}
                        />
                        {formik.touched[fieldMeta.name] &&
                            <FormHelperText>{formik.errors[fieldMeta.name]}</FormHelperText>}
                    </FormControl>
                );
            case 'autocomplete':
                return(
                    <Autocomplete
                        fullWidth
                        freeSolo
                        sx={{minWidth: '150px'}}
                        key={'autocomplete_' + field_id + '_' + sectionId}
                        options={fieldMeta.options ?? []}
                        // onInputChange={(event, newInputValue) => {
                        //     formik.setFieldTouched(fieldMeta.name, true);
                        //     formik.setFieldValue(fieldMeta.name, newInputValue);
                        // }}
                        onChange={(event, value) => {
                            formik.setFieldTouched(fieldMeta.name, true);
                            formik.setFieldValue(fieldMeta.name, value);
                        }}
                        renderInput={(params) => <TextField {...params} variant={'outlined'} label={fieldMeta.label}/>}
                    />
                )
            case 'label':
                return (
                    <h5 key={'label_' + field_id + '_' + sectionId}>{fieldMeta.label}</h5>
                )
            case 'element':
                return (
                    <div key={'element_' + field_id + '_' + sectionId} className="nested-form-element">
                        {fieldMeta.element}
                    </div>
                )
            case 'richText' :
                return (
                    <span className="flex flex-col p-2" key={'richText_' + field_id + '_' + sectionId}>
                        <Typography>{fieldMeta.label}</Typography>
                        <RichTextEditor readOnly={false} state={formik.values[fieldMeta.name]} formChange={(state) => {
                            formik.setFieldValue(fieldMeta.name, state)
                        }
                        }/>
                    </span>
                )
            default:
                return (<div></div>)
        }
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={onCancel}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onCancel}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {title}
                        </Typography>
                        <Stack direction={'row'}>
                            {additionalButtons}
                            <Button autoFocus color="inherit" onClick={(event) => formik.handleSubmit()}>
                                save
                            </Button>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Stack direction={'column'} spacing={2} p={2}>
                    {sections.map((currentSection, section_id) => {
                        return (
                            <Stack direction={'row'} key={'section_' + section_id}>
                                {(currentSection.title && currentSection?.showTitle) && <h5>{currentSection.title}</h5>}
                                <Stack direction={{xs: 'column', sm: 'row'}} justifyContent={'space-evenly'} spacing={2}
                                       p={2} key={'fields_' + section_id}>
                                    {
                                        fields.filter(field => field.section === currentSection.title)
                                            .map((curField, id) => {
                                                let isRequired = !!validation[curField.name] && !!validation[curField.name].required;
                                                if (curField.toolTip) {
                                                    return (
                                                        <Tooltip title={curField.toolTip} placement="bottom" arrow
                                                                 key={'tooltip_' + id + '_' + section_id}>
                                                            {fieldRenderer(curField, id, section_id, isRequired)}
                                                        </Tooltip>
                                                    )
                                                } else {
                                                    return <>{fieldRenderer(curField, id, section_id, isRequired)}</>
                                                }
                                            })
                                    }
                                </Stack>
                            </Stack>
                        )
                    })}
                </Stack>
            </Dialog>
        </div>
    );
}

export default FullScreenDialog;

export interface Field {
    section: string;
    name: string;
    type: string;
    label: string;
    variant?: string;
    disabled?: boolean;
    options?: { value: any, label: string }[];
    element?: ReactElement;
    enableCheckBox?: boolean;
    multiple?: boolean;
    inputProps?: { [key: string]: any };
    onChange?: (event: any, formik: any) => void;
    toolTip?: string;
}
