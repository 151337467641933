import React, {useEffect, useState} from "react";
import {
    Divider,
    IconButton,
    Paper,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Stack,
    SwipeableDrawer,
    Typography
} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import CharacterBio from "./character-header/CharacterBio";
import {fetchCharacter} from "../redux/character/characterSlice";
import {Status} from "../redux/status";
import Primary from "./primary/Primary";
import {NewspaperRounded, Note, Settings} from "@mui/icons-material";
import NoteDialog from "./primary/notes/NoteDialog";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import HealthTracker from "./character-header/HealthTracker";
import Dialog from "@mui/material/Dialog";
import RestForm from "./primary/RestForm";
import ThemeToggle from "../../shared/components/ThemeToggle";
import firebase from 'firebase/compat/app';
import {SpellDialog} from "./primary/spells/SpellDialog";
import {SpellModel} from "../../shared/models/spell.model";
import {createSpell, updateSpell} from "../redux/character/spellSlice";
import {castingTable} from "./primary/spells/spell-table/SpellTracker";

export function Character() {
    const navigate = useNavigate();
    const [manage, setManage] = useState<boolean>(false);
    const [rest, setRest] = useState<boolean>(false);
    const {id} = useParams();
    const {user} = useAppSelector((state) => state.application);
    const dispatch = useAppDispatch();
    const {character, status} = useAppSelector((state) => state.character);
    const {health} = useAppSelector((state) => state.status);
    const addNote = useAppSelector((state) => state.notes.openDialog);
    const addSpell = useAppSelector((state) => state.spells.openDialog);
    const {editingSpell} = useAppSelector((state) => state.spells);

    useEffect(() => {
        if (status === Status.Idle) {
            dispatch(fetchCharacter({email: user.email, id: id}));
        }
    }, [status, dispatch]);

    const handleChange = (action: { type: string, payload: { [key: string]: any } }) => {
        action.payload = {...action.payload, email: user?.email};
        dispatch(action);
    };

    const actions = [
        {
            icon: <Note/>, name: 'Note', action: () => {
                dispatch({type: 'notes/openNote', payload: true});
            }
        },
        {
            icon: <NewspaperRounded/>, name: 'Spell', action: () => {
                dispatch({type: 'spells/openSpell', payload: true});
            }
        },
    ];

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setManage(open);
    };

    const handleRest = (value?: boolean) => {
        if (value) {
            dispatch({type: 'spells/resetSpellsCasted'});
            dispatch({type: 'status/incrementHealthByAmount', payload: Math.floor(character.Health / 4)})
        }
        setRest(false);
    }

    const handleCloseSpell = (newSpell?: SpellModel) => {
        if (newSpell) {
            newSpell.casts = castingTable[character.Power][newSpell.rank];
            dispatch(createSpell({
                id: id, email: user.email, data: newSpell
            }));
        } else if (newSpell && editingSpell) {
            dispatch(updateSpell({
                id: id, email: user.email, data: newSpell
            }));
            dispatch({type: 'spells/editSpell', payload: null})
        }
        dispatch({type: 'spells/openSpell', payload: false});
    };

    return (
        <Stack>
            <>
                {/*TODO moves this to its own component like Settings.jsx or something*/}
                <SwipeableDrawer
                    anchor={'right'}
                    open={manage}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    sx={{minWidth: '100%'}}
                >
                    <div className="w-[300px] flex-col justify-center">
                        <Stack direction={'row'} justifyContent={'space-between'} p={1}>
                            <div className={'flex-col'}>
                                <Typography>{user.name}</Typography>
                                <Typography>{user.email}</Typography>
                                <Button onClick={() => {
                                    firebase.auth().signOut();
                                    navigate('/');
                                }}>Sign-out</Button>
                            </div>
                            <ThemeToggle/>
                        </Stack>
                        <Divider/>
                        <Stack direction={'column'} spacing={4} mt={4}>
                            <Button>
                                <Link to={`/characters/${character.identity}/edit`} state={character}>
                                    Edit Character
                                </Link>
                            </Button>
                            <Button>
                                <Link to={`/characters`}>
                                    Character List
                                </Link>
                            </Button>
                        </Stack>
                    </div>
                </SwipeableDrawer>
            </>
            {status === Status.Loading ? <div>Loading</div> : <>
                <NoteDialog open={addNote} id={id} email={user.email}/>
                <SpellDialog handleClose={(newSpell) => handleCloseSpell(newSpell)} open={addSpell} editing={!!editingSpell}/>
                <AppBar elevation={0}>
                    <Paper className={'flex flex-row'} elevation={0}>
                        <div>
                            <IconButton onClick={toggleDrawer(true)} color={'primary'}>
                                <Settings/>
                            </IconButton>
                        </div>
                        <div className="flex flex-col p-2 m-2 justify-center flex-1 items-center">
                            <div className="flex flex-col">
                                <Typography variant={'h6'}
                                            className={'text-center'}>{character?.Name}</Typography>
                                <Typography>{character?.Ancestry}</Typography>
                                <Typography
                                    variant={'subtitle1'}>{character?.Paths ? Object.keys(character?.Paths).join(', ') : ''}</Typography>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center p-1">
                            <HealthTracker currentHealth={health?.current ?? character.CurrentHealth} max={health?.max ?? character.Health}/>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Button onClick={() => setRest(true)} color="warning">Rest</Button>
                                <>
                                    <Dialog open={rest} onBackdropClick={() => setRest(false)}>
                                        <RestForm handleClose={handleRest}/>
                                    </Dialog>
                                </>
                                <Button>Afflictions</Button>
                            </Stack>
                        </div>
                    </Paper>
                </AppBar>
                <Stack className={'mt-[110px] h-[calc(100vh_-_110px)] overflow-hidden'} direction={{lg: 'row', xs: 'column'}}>
                    <Box>
                        <CharacterBio character={character}/>
                    </Box>
                    {status === Status.Succeeded && (<>
                        <Primary character={character} dispatch={handleChange} email={user.email}/>
                    </>)}
                </Stack>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{position: 'fixed', bottom: 16, right: 16}}
                    icon={<SpeedDialIcon/>}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            tooltipOpen
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => action.action()}
                        />
                    ))}
                </SpeedDial>
            </>}
        </Stack>
    );
}
