import {CharacterModel} from "./Character.model";

export type Action = {
    type: string;
    payload: { [key: string]: any };
};

export const characterReducer = (state: CharacterModel, action: { type: string, payload: { [key: string]: any } }) => {
    if (action.type === 'update') {
        return new CharacterModel().deserialize({...state, ...action.payload});
    } else if (action.type === 'spells') {
        return new CharacterModel().deserialize({...state, ...action.payload});
    } else if (action.type === 'CREATE_SPELL') {
        {
            fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/create/${state.identity}`, {
                method: "POST",
                body: JSON.stringify({
                    email: action.payload.email,
                    spell: action.payload.spell
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((res) => res.json().then((data) => {
                let spells = [...(state.Spells ?? []), data];
                return new CharacterModel().deserialize({...state, ...{Spells: spells}});
            }));
        }
        return new CharacterModel().deserialize({...state, ...action.payload});
    } else if (action.type === 'DELETE_SPELL') {
        {
            fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/delete/${state.identity}`, {
                method: "DELETE",
                body: JSON.stringify({
                    email: action.payload.email,
                    spell: action.payload.spell
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((res) => res.json().then((data) => {
                return new CharacterModel().deserialize({...state});
            }));
        }
        return new CharacterModel().deserialize({...state, ...action.payload});
    }else if (action.type === 'GET_SPELLS') {
        {
            fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/list/${state.identity}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((res) => res.json().then((data) => {
                let spells = [...(state.Spells ?? []), ...data];
                console.log({...state, ...{Spells: spells}});
                return new CharacterModel().deserialize({...state, ...{Spells: spells}});
            }));
        }
        return new CharacterModel().deserialize({...state, ...action.payload});
    }else if (action.type === 'UPDATE_SPELL') {
        fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/spell/edit/${state.identity}`, {
            method: "POST",
            body: JSON.stringify({
                email: action.payload.email,
                spell: action.payload.spell
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => res.json().then((data) => {
            let spells = [...(state.Spells ?? []), data];
            return new CharacterModel().deserialize({...state, ...{Spells: spells}});
        }));
        return new CharacterModel().deserialize({...state, ...action.payload});
    } else if (action.type === 'health') {
        return new CharacterModel().deserialize({...state, ...action.payload});
    } else {
        throw new Error();
    }
}
