import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './service-workers/reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './service-workers/serviceWorkerRegistration';
import App from './app/App';
import {Provider} from "react-redux";
import store, {saveState} from "./app/store";

store.subscribe(() => {
    saveState({
        application: store.getState().application,
        characters: store.getState().characters,
        character: store.getState().character,
        status: store.getState().status,
        equipment: store.getState().equipment,
        spells: store.getState().spells,
        notes: store.getState().notes
    });
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

ReactDOM.render(
    <BrowserRouter>
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.register();
