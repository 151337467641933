import * as React from 'react';
import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {SpellModel} from "../../../../shared/models/spell.model";
import {Divider, Rating, Stack} from "@mui/material";
import RichTextEditor from "../../../../shared/components/rich-text-editor/RichTextEditor";
import Button from "@mui/material/Button";
import {CircleOutlined, CircleRounded} from "@mui/icons-material";
import {castingTable} from "./spell-table/SpellTracker";
import {useAppDispatch} from "../../../redux/hooks";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function ExpandableSpellCard({spell, power, casts}: { spell: SpellModel, power: number, casts: number }) {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#6750A4',
        },
        '& .MuiRating-iconHover': {
            color: '#958da4',
        },
    });
    const dispatch = useAppDispatch();
    return (
        <Card sx={{maxWidth: 350}} raised={true}>
            <CardContent>
                <Typography sx={{fontSize: 14}} gutterBottom>
                    {spell.name}
                </Typography>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {`${spell.tradition} ${spell.actionType} ${spell.rank}`}
                </Typography>
                <StyledRating
                    readOnly
                    value={casts}
                    name="customized-color"
                    defaultValue={0}
                    max={castingTable[power][spell.rank]}
                    icon={<CircleRounded fontSize="inherit"/>}
                    emptyIcon={<CircleOutlined fontSize="inherit"/>}
                />
            </CardContent>
            <CardActions disableSpacing>
                <Button disabled={castingTable[power][spell.rank] === casts}
                        onClick={() => dispatch({
                            type: 'spells/castSpell', payload: spell?.identity
                        })}>Cast</Button>
                <Button disabled={casts === 0}
                        onClick={() => dispatch({
                            type: 'spells/uncastSpell', payload: spell?.identity
                        })}>Uncast</Button>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon/>
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Stack>
                        <Typography><strong>Requirement</strong> {spell.requirement}</Typography>
                        {!!spell.target && <Typography><strong>Target</strong> {spell?.target}</Typography>}
                        {!!spell.area && <Typography><strong>Area</strong> {spell?.area}</Typography>}
                        {!!spell?.duration && <Typography>Duration {spell?.duration}</Typography>}
                    </Stack>
                    <Divider color={"#9b0000"}/>
                    <Stack>
                        <Typography>{spell.actionType && <strong>{spell.actionType}</strong>}</Typography>
                        <RichTextEditor readOnly={true} state={spell.description}/>
                        {(spell.spellType === 'Attack' && !!spell.additionalEffect) &&
                            <Typography><strong>Attack Roll 20+</strong> {spell?.additionalEffect}</Typography>}
                    </Stack>
                </CardContent>
            </Collapse>
        </Card>
    );
}