import React, {ChangeEvent, useState} from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextareaAutosize
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

type Props = {
    type: 'Background' | 'Personality',
    properties: string[],
    editing: boolean,
    handleClose: (value?: { property: string, rollValue: number | undefined, description: string }, index?: number) => void
}

function DescriptionDialog(props: Props) {
    const properties = props.properties;
    const [open, setOpen] = React.useState(false);
    const [stayOpen, setStayOpen] = useState<boolean>(true);
    const [form, setForm] = useState({property: properties[0], rollValue: undefined, description: ''});
    const [inputValue, setInputValue] = React.useState('');

    const handleOk = () => {
        props.handleClose({...form, property: inputValue !== '' ? inputValue : form.property});
        setOpen(stayOpen);
        setForm({property: properties[0], rollValue: undefined, description: ''});
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const newState = {...form, [name]: name === 'rollValue' ? parseInt(value ?? '0') : value}
        setForm(newState);
    }

    const handlePropertyChange = (newValue: string | null) => {
        const newState = {...form, 'property': newValue ?? ''};
        setForm(newState);
    }
    return (
        <div>
            <Button onClick={() => setOpen(true)}>Add {props.type}</Button>
            <Dialog open={open}>
                <DialogTitle>{props.editing ? 'Create' : 'Editing'}</DialogTitle>
                <DialogContent className="flex flex-col">
                    <Autocomplete
                        freeSolo
                        options={properties.map((option) => option)}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        onChange={(event: any, newValue: string | null) => handlePropertyChange(newValue)}
                        renderInput={(params) => <TextField {...params} label="Property"/>}
                    />
                    <TextField
                        label={'Roll Value'}
                        name={'rollValue'}
                        onChange={handleInputChange}
                        type={'number'}
                        style={{marginRight: '5px'}}/>
                    <TextareaAutosize placeholder={'Description'}
                                      name={'description'}
                                      value={form.description}
                                      minRows={3}
                                      style={{height: 56}}
                                      onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    {!props.editing && <FormGroup>
                        <FormControlLabel control={<Switch
                            id={'stay-open'}
                            checked={stayOpen}
                            onChange={() => setStayOpen(!stayOpen)}
                            inputProps={{'aria-label': 'controlled'}}
                        />} label="Create Another"/>
                    </FormGroup>}
                    <span style={{flex: 1}}/>
                    <Button onClick={handleCancel} color={'inherit'}>Cancel</Button>
                    <Button onClick={handleOk} color={'primary'}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DescriptionDialog;