import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "../status";

const initialState = {
    status: Status.Idle,
    equipment: new Array(),
    error: null
}

export const equipmentSlice = createSlice({
    name: 'equipment',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getEquipment.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(getEquipment.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.equipment = action.payload;
            })
            .addCase(getEquipment.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(createEquipment.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(createEquipment.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.equipment = [...state.equipment, action.payload];
            })
            .addCase(createEquipment.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(updateEquipment.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(updateEquipment.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                const index = state.equipment.findIndex((item) => item.identity === action.payload.identity);
                state.equipment[index] = action.payload;
            })
            .addCase(updateEquipment.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(deleteEquipment.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(deleteEquipment.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                const index = state.equipment.findIndex((item) => item.identity === action.payload);
                state.equipment.splice(index, 1);
            })
            .addCase(deleteEquipment.rejected, (state, action) => {
                state.status = Status.Failed;
            })
    }
});
export const getEquipment = createAsyncThunk('equipment/fetchEquipment', async (payload: { id: string, email: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/equipment?id=${payload.id}&email=${payload?.email}`)
        .then(res => res.json());
})

export const updateEquipment = createAsyncThunk('equipment/update', async (payload: { email: string, id: string, data: any, field?: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/equipment/${payload.id}/update`, {
        method: "POST",
        body: JSON.stringify({email: payload.email, equipment: payload.data, field: payload?.field}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export const createEquipment = createAsyncThunk('equipment/create', async (payload: { email: string, id: string, data: any }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/equipment/${payload.id}/create`, {
        method: "POST",
        body: JSON.stringify({email: payload.email, equipment: payload.data}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export const deleteEquipment = createAsyncThunk('equipment/delete', async (payload: { email: string, id: string, equipmentID: string }) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/equipment/${payload.id}/delete`, {
        method: "DELETE",
        body: JSON.stringify({email: payload.email, equipmentID: payload.equipmentID}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export default equipmentSlice.reducer;