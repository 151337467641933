import PropTypes from "prop-types";
import {Divider, Stack, SwipeableDrawer, Typography} from "@mui/material";
import {CharacterModel} from "../Character.model";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import HealthTracker from "./HealthTracker";
import Dialog from "@mui/material/Dialog";
import RestForm from "../primary/RestForm";
import ThemeToggle from "../../../shared/components/ThemeToggle";
import firebase from 'firebase/compat/app';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {AttributesAndCharacteristics} from "../attributes-and-characteristics/AttributesAndCharacteristics";
import Box from "@mui/material/Box";

export interface ICharacterBio {
    character: CharacterModel;
}

CharacterBio.propTypes = {
    character: PropTypes.object
}

function CharacterBio(props: ICharacterBio) {
    const {user} = useAppSelector((state) => state.application);
    const character = props.character;
    const [manage, setManage] = useState<boolean>(false);
    const [rest, setRest] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setManage(open);
    };

    const handleRest = (value?: boolean) => {
        if (value) {
            dispatch({type: 'spells/resetSpellsCasted'});
            dispatch({type: 'status/incrementHealthByAmount', payload: Math.floor(character.Health / 4)})
        }
        setRest(false);
    }

    return (<Box width={'100%'}>
        <Stack direction={{lg: 'column', xs: 'row'}} className={'p-1'}>
            <div className="grid grid-cols-[1fr_repeat(1,_auto)_1fr] justify-center items-center">
                <AttributesAndCharacteristics character={character}/>
            </div>
            <div className="flex flex-row">
                <div className="ring-1 rounded p-2 m-2">
                <span className="flex flex-col p-2">
                    <Typography>Professions</Typography>
                    <Divider color={'secondary'}/>
                    <span>{character.Professions}</span>
                </span>
                    <span className="flex flex-col p-2">
                    <Typography>Languages</Typography>
                    <Divider color={'secondary'}/>
                    <span>{character.Languages}</span>
                </span>
                </div>
            </div>
        </Stack>
    </Box>);
}

export function BioShield(props: { field: string, value: number }) {
    return (<div
        className="flex flex-col justify-center items-center ring-offset-1 ring-offset-red-900 ring-1 ring-red-800 ring-inset rounded-xl p-2">
            <span className='flex flex-col justify-center items-center text-inherit font-bold speed-input'>
                {props.value || 0}
            </span>
        <span className="text-inherit font-bold">
                <Typography variant={'subtitle2'}>{props.field}</Typography>
            </span>
    </div>)
}

export default CharacterBio;
