import * as React from "react";
import "./AttributesAndCharacteristics.css"
import Attribute from "./attribute/Attribute";
import { CharacterModel } from "../Character.model";
import Characteristic from "./characteristic/Characteristic";
import {Divider, Paper, Stack, Typography} from "@mui/material";

export interface ITalents {
    character: CharacterModel
}

export class AttributesAndCharacteristics extends React.Component<ITalents> {
    render() {
        let character = this.props.character;
        const attributes: ("Strength" | "Agility" | "Intellect" | "Will")[] = ["Strength", "Agility", "Intellect", "Will"];
        const characteristics: ("Perception" | "Power" | "Speed" | "Defense")[]
            = ["Perception", "Power", "Speed" , "Defense"];
        const secondary = ["Insanity", "Damage", "Corruption",]

        return (
            <Stack>
                <Paper className={'w-[250px] m-1 p-2'} variant={'outlined'}>
                    <Stack>
                        {attributes.map((attribute) => {
                            return (
                                <div>
                                    <Stack direction={'row'}>
                                        <Typography className='flex-[15%]'>{attribute}</Typography>
                                        <Typography className={'flex-1'}>{character[attribute]}</Typography>
                                        <Typography>{character[attribute] - 10 < 0 ? '' : '+'}{character[attribute] - 10}</Typography>
                                    </Stack>
                                </div>
                            )
                        })}
                    </Stack>
                </Paper>
                <Paper className={'w-[250px] m-1 p-2'} variant={'outlined'}>
                    <Stack>
                        {characteristics.map((characteristic) => {
                            return (
                                <div>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography className={'flex-1'}>{characteristic}</Typography>
                                        <Typography>{character[characteristic] ?? 0}</Typography>
                                    </Stack>
                                </div>
                            )
                        })}
                    </Stack>
                </Paper>
            </Stack>
        );
    }
}
