import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {StyledFirebaseAuth} from "react-firebaseui";
import {useEffect} from "react";
import {verifyUser} from "../app/redux/applicationSlice";
import {fetchCharacters} from "../app/redux/characters/charactersSlice";
import {useAppDispatch} from "../app/redux/hooks";

// Configure Firebase.
const firebaseConfig = {
    apiKey: "AIzaSyCo53l_CReUiBNlspoqOApX2hP5klB7FnY",
    authDomain: "sheets-342913.firebaseapp.com",
    projectId: "sheets-342913",
    storageBucket: "sheets-342913.appspot.com",
    messagingSenderId: "630175455072",
    appId: "1:630175455072:web:b7eb798490a8a0ef823005",
    measurementId: "G-7WT2G9DFKS"
};
firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

export function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async user => {
            const token = await user?.getIdToken();
            if(!token) {
                return;
            }
            try{
                await dispatch(verifyUser(token))
                    .unwrap()
                    .then((email) => {
                        dispatch(fetchCharacters(email));
                        navigate("/characters");
                    });
            } catch (e) {
                console.log(e);
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    return (
        <div className="flex flex-col justify-center items-center h-[100vh] w-full">
            <Typography variant={'h4'}>Welcome to Sheets!</Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        </div>
    )
}