import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "./status";

const initialState = {
    tab: 0,
    darkMode: localStorage.getItem('darkMode') === 'true',
    form: null,
    activeAction: {
        type: 'weapon',
        weapon: {
            identity: '1',
            active: true,
            name: 'Unarmed Strike',
            price: 0,
            availability: 'Common',
            quantity: 1,
            description: '',
            consumable: false,
            equipped: true,
            damage: '1d3',
            hands: 1,
            attribute: 'Strength',
            type: 'weapons'
        }
    },
    activeTalents: new Array(),
    user: {
        name: '',
        email: null,
        status: Status.Idle
    }
}

export const applicationSlice = createSlice({
        name: 'application',
        initialState,
        reducers: {
            setTheme: (state, action) => {
                state.darkMode = action.payload;
                localStorage.setItem('darkMode', action.payload);
            },
            setTab: (state, action) => {
                state.tab = action.payload;
            },
            setForm: (state, action) => {
                state.form = action.payload;
            },
            setAction: (state, action) => {
                state.activeAction = action.payload;
            },
            setActiveTalent: (state, action) => {
                const index = state.activeTalents.findIndex((talent) => talent === action.payload);
                if(index >= 0) {
                    state.activeTalents.splice(index, 1);;
                } else {
                    state.activeTalents.push(action.payload);
                }
            }
        },
        extraReducers(builder) {
            builder.addCase(verifyUser.pending, (state, action) => {
                state.user.status = Status.Loading;
            })
                .addCase(verifyUser.fulfilled, (state, action) => {
                    state.user.status = Status.Succeeded;
                    state.user.email = action.payload;
                })
                .addCase(verifyUser.rejected, (state, action) => {
                    state.user.status = Status.Failed;
                })
        }
    }
);

export const verifyUser = createAsyncThunk('application/verifyUser', async (payload: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/auth/google`, {
        method: "POST",
        body: JSON.stringify({
            token: payload
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export default applicationSlice.reducer;