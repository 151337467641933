import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Status} from "../status";
import {CharacterModel} from "../../character/Character.model";

const initialState = {
    status: Status.Idle,
    character: {} as CharacterModel,
    error: null
}

export const characterSlice = createSlice({
    name: 'character',
    initialState,
    reducers: {
        modifyAttributes: (state, action) => {
            Object.keys(action.payload.modifiers).forEach((value) => {
                state.character[value] = parseInt(state.character[value]) + action.payload.modifiers[value];
            });
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCharacter.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(fetchCharacter.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                state.character = action.payload;
            })
            .addCase(fetchCharacter.rejected, (state, action) => {
                state.status = Status.Failed;
            })
            .addCase(updateCharacter.pending, (state, action) => {
                state.status = Status.Loading;
            })
            .addCase(updateCharacter.fulfilled, (state, action) => {
                state.status = Status.Succeeded;
                // TODO this is reseting the entire app
                // Break apart character into smaller chunks, like an attribute slice
                state.character = {...state.character , ...action.payload};
            })
            .addCase(updateCharacter.rejected, (state, action) => {
                state.status = Status.Failed;
            })
    }
});

export const fetchCharacter = createAsyncThunk('character/fetchCharacter', async (payload: {email: string, id: string}) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/character?id=${payload.id}&email=${payload?.email}`)
        .then(res => res.json());
});

export const updateCharacter = createAsyncThunk('character/editCharacter', async (payload: {email: string, id: string, field: string, data: any}) => {
    return await fetch(`${process.env.REACT_APP_API_URL ?? ''}/api/v1/character/edit/${payload.id}`, {
        method: "POST",
        body: JSON.stringify({email: payload.email, field: payload.field, data: payload.data}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json());
});

export default characterSlice.reducer;