import { ReactChild } from "react";

type Props = {
    children: ReactChild | ReactChild[],
    active: boolean
}

function ListItem(props: Props) {
    const { children } = props;
    return (
        <div className={`m-1 max-w-sm rounded border border-[#6E9075] shadow-lg flex items-center space-x-4 ${props.active ? 'bg-[#6E9075] text-white' : ''}`}>
            {children}
        </div>
    )
}

export default ListItem;