import React, {useState} from "react";
import {FormControl, TextField} from "@mui/material";
import {SpellModel} from "../../../../../shared/models/spell.model";

function SpellsFilter(props: { spellList: SpellModel[], filterSpells: (filteredList: SpellModel[]) => void }) {
    const [activeRanks, setActiveRanks] = useState<Map<number, boolean>>(new Map([
        [0, false],
        [1, false],
        [2, false],
        [3, false],
        [4, false],
        [5, false],
        [6, false],
        [7, false],
        [8, false],
        [9, false],
        [10, false],

    ]));
    const [filter, setFilter] = useState<string>('');
    const toggleRank = (rank: number, active: boolean) => {
        setActiveRanks((curr) => {
            const newMap = curr;
            newMap.set(rank, active);
            return newMap;
        });
        filterSpells(filter);
    }

    function filterSpells(value: string) {
        setFilter(value);
        let newSpellList = props.spellList.filter((spell) => {
            let include: boolean = value ? spell.name.includes(value.toUpperCase()) : true;
            if (include && Array.from(activeRanks.values()).includes(true)) {
                include = activeRanks.get(spell.rank) ?? false;
            }
            return include;
        });

        props.filterSpells(newSpellList);
    }

    return (
        <div>
            <div className="p-2">
                <FormControl fullWidth>
                    <TextField type={'text'} label={'Enter Spell Name'} size={"small"} variant={'outlined'}
                               value={filter}
                               onChange={(event) => filterSpells(event.target.value)}/>
                </FormControl>
            </div>
            <div className="p-1 grid grid-cols-11 gap-1">
                <RankButton rank={0} rankToggled={toggleRank}/>
                <RankButton rank={1} rankToggled={toggleRank}/>
            </div>
        </div>
    )

}

export default SpellsFilter;

function RankButton(props: { rank: number, rankToggled: (rank: number, active: boolean) => void }) {
    const [active, setActive] = useState(false);
    return <span
        className={`ring-1 rounded text-center font-semibold ${active ? 'bg-sky-500 text-white' : ''}`}
        onClick={() => {
            setActive(!active);
            props.rankToggled(props.rank, !active);
        }
        }>{props.rank}</span>
}