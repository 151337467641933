import {Divider, Typography} from "@mui/material";
import RichTextEditor from "./rich-text-editor/RichTextEditor";

function TalentLabel(name: string, description: object) {
    return (
        <span className="flex flex-col p-2">
            <Typography>{name}</Typography>
            <Divider color={"secondary"}/>
            <RichTextEditor readOnly={true} state={description}/>
        </span>
    )
}

export default TalentLabel;