import React from "react";
import BlockStyleButton from "./BlockStyleButton";
import {FormatListBulleted, FormatListNumbered} from "@mui/icons-material";
import {Stack} from "@mui/material";

function StyleToolBar({editorState, onToggle}) {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <Stack direction={'row'} p={1}>
            {/*<HeaderStyleDropdown active={blockType} onToggle={onToggle}/>*/}
            {BLOCK_TYPES.map((type => {
                return (
                    <BlockStyleButton active={type.style === blockType} label={type.label} onToggle={onToggle}
                                      style={type.style} key={type.label} type={type}/>
                )
            }))}
        </Stack>
    )
}

export default StyleToolBar;

export const BLOCK_TYPES = [
    // {label: " “ ” ", style: "blockquote"},
    {label: "UL", style: "unordered-list-item", icon: <FormatListBulleted/>},
    {label: "OL", style: "ordered-list-item", icon: <FormatListNumbered/>},
    // {label: "{ }", style: 'code-block'}
];
export const BLOCK_TYPE_HEADINGS = [
    {label: "H1", style: "header-one"},
    {label: "H2", style: "header-two"},
    {label: "H3", style: "header-three"},
    {label: "H4", style: "header-four"},
    {label: "H5", style: "header-five"},
    {label: "H6", style: "header-six"}
]

export function getBlockStyle(block) {
    switch (block.getType()) {
        case "blockquote":
            return "RichEditor-blockquote";
        default:
            return null;
    }
}