import {Box, SwipeableDrawer, Typography} from "@mui/material";
import * as React from "react";
import './Attacks.css';
import {Action} from "./Attacks.model";

function Attacks(props: {attacks: { [key: string]: Action }}) {
    const [open, setOpen] = React.useState(false);
    const [attack, setAttack] = React.useState<{ name: string, description: string }>({name: '', description: ''});

    const toggleDrawer =
        (attack: { name: string, description: string }) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setAttack(attack);
                setOpen(!open);
            };

    const list = () => (
        <Box
            role="presentation"
            onClick={toggleDrawer(attack)}
            onKeyDown={toggleDrawer(attack)}
            style={{maxWidth: 250}}
        >
            <Typography variant={'h6'} className={'attack-description'}>{attack.name}</Typography>
            <Typography variant={'subtitle1'} className={'attack-description'}>{attack.description}</Typography>
        </Box>
    );

    return (<Box>
            <React.Fragment>
                <div className="grid grid-cols-2 gap-2">{Object.keys(props.attacks).map((attack, index) =>
                    <span key={attack}
                          onClick={toggleDrawer({name: attack, description: props.attacks[attack].description})}>
                        {attack}
                    </span>)}
                </div>
                <SwipeableDrawer
                    variant={'temporary'}
                    anchor={'right'}
                    open={open}
                    onClose={toggleDrawer(attack)}
                    onOpen={toggleDrawer(attack)}
                >
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>
        </Box>
    )
}

export default Attacks;
