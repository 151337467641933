import React from 'react';
import {ContentState, convertFromRaw, convertToRaw, Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import StyleToolBar, {getBlockStyle} from "./StyleToolBar";
import {Divider, Paper, Stack} from "@mui/material";

function RichTextEditor({
                            readOnly,
                            state,
                            formChange
                        }: { readOnly: boolean, state: any, formChange?: (state) => void }) {
    const getContent = () => {
        if (!state) {
            return EditorState.createEmpty();
        } else if (typeof state === 'string') {
            return EditorState.createWithContent(ContentState.createFromText(state))
        }
        return EditorState.createWithContent(convertFromRaw(state));
    };
    const [editorState, setEditorState] = React.useState(
        () => getContent(),
    );

    const handleKeyCommand = (command: string) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }
    const toggleBlockType = blockType => {
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const onChange = (state) => {
        setEditorState(state);
        if (formChange) {
            formChange(convertToRaw(state.getCurrentContent()));
        }
    }

    // TODO add buttons to toggle Bold, Italic, Underline etc .. RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC')
    return (
        <Stack pt={1}>
            <Paper sx={{display: readOnly ? 'none' : 'block'}}>
                <StyleToolBar editorState={editorState}
                              onToggle={toggleBlockType}/>
                <Divider/>
            </Paper>
            <Paper elevation={readOnly ? 0 : 1}
                className={"p-2 text-left overflow-y-auto overflow-x-hidden text-[90%] " + (readOnly ? "" : "min-h-[30%] h-[20vh] w-[34vw]")}>
                <Editor className={'w-[40vw] h-[10vh] p-2 focus:ring-sky-500'} editorState={editorState}
                        onChange={onChange} handleKeyCommand={handleKeyCommand} blockStyleFn={getBlockStyle}
                        readOnly={readOnly}/>
            </Paper>
        </Stack>
    );
}

export default RichTextEditor;