import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import DialogContent from "@mui/material/DialogContent";
import {Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

function RestForm(props: { handleClose: (value?: boolean) => void }) {
    return (
        <div>
            <DialogTitle>Long or Short rest?</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked/>} label="Heal Healing Rate?"/>
                </FormGroup>
                <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked/>} label="Replenish Spell Slots?"/>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()}>Cancel</Button>
                <Button onClick={() => props.handleClose(true)}>Continue</Button>
            </DialogActions>
        </div>
    )
}

export default RestForm;