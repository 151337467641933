import {SpellModel} from "../../../../../shared/models/spell.model";
import React, {Fragment, useState} from "react";
import {Button, Stack, Typography} from "@mui/material";
import SpellCard from "../SpellCard";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {castingTable} from "./SpellTracker";
import DialogTitle from "@mui/material/DialogTitle";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import DataTable from "../../../../../shared/components/table/table";
import {ColumnDef} from "@tanstack/react-table";

function SpellTable(props: {
    spells: SpellModel[], power: number, dispatch: (action: { type: string, payload: { [key: string]: any } }) => void; spellsCasted: { [rank: number]: number }, editing: boolean, removeSpell: (spell: SpellModel) => void, editSpell: (spell: SpellModel) => void
}) {
    const rankedSpells: { [rank: number]: SpellModel[] } = props.spells.reduce((memo: { [rank: number]: SpellModel[] }, spell) => {
        if (!memo[spell.rank]) {
            memo[spell.rank] = [];
        }
        memo[spell.rank].push(spell);
        return memo;
    }, {});
    const spellsCasted = useAppSelector((state) => state.spells.spellsCasted);
    const [spellDetail, setSpellDetail] = useState<SpellModel>();
    const [spellDialogOpen, setSpellDialogOpen] = useState<boolean>(true);
    const [removeSpell, setRemoveSpell] = useState<SpellModel | null>(null);
    const dispatch = useAppDispatch();
    const castSpell = () => {
        dispatch({
            type: 'spells/castSpell', payload: spellDetail?.identity
        });
    }
    const calculateSpellsCasted = (numberOfCasts: number, casts: number) => {
        if (casts && numberOfCasts) {
            return numberOfCasts - casts
        } else if (numberOfCasts) {
            return numberOfCasts
        } else {
            return '---';
        }
    }

    const spellColumns: ColumnDef<SpellModel>[] = [
        {
            header: '',
            accessorFn: row => row.name ?? '---',
            id: 'cast',
            cell: ({row, getValue}) => {
                const spell = row.original;
                return (
                    <Stack direction={'row'}>
                        <Button disabled={castingTable[props.power][spell.rank] === spellsCasted[spell.identity]}
                                onClick={() => dispatch({
                                    type: 'spells/castSpell', payload: spell?.identity
                                })}>Cast</Button>
                        <Button disabled={spellsCasted[spell.identity] === 0}
                                onClick={() => dispatch({
                                    type: 'spells/uncastSpell', payload: spell?.identity
                                })}>Uncast</Button>
                    </Stack>
                )
            },
            footer: props => props.column.id,
        },
        {
            header: 'Spell',
            accessorFn: row => row.name ?? '---',
            id: 'name',
            cell: ({row, getValue}) => (
                <div>
                    <span onClick={() => {
                        // TODO get the spell from the spell data
                        setSpellDetail(row.original);
                        setSpellDialogOpen(true);
                    }}>{getValue()}</span>
                </div>
            ),
            footer: props => props.column.id,
        },
        {
            header: 'Tradition',
            accessorFn: row => row.tradition ?? '---',
            id: 'tradition',
            cell: info => info.getValue(),
            footer: props => props.column.id,
        },
        {
            header: 'Type',
            accessorFn: row => row.spellType ?? '---',
            id: 'spellType',
            cell: info => info.getValue(),
            footer: props => props.column.id,
        },
        {
            header: 'Time',
            accessorFn: row => row.actionType ?? '---',
            id: 'actionType',
            cell: info => info.getValue(),
            footer: props => props.column.id,
        },
        {
            header: 'DMG',
            accessorFn: row => row?.damage ?? '---',
            id: 'damage',
            cell: info => info.getValue(),
            footer: props => props.column.id,
        },
        {
            header: 'Casts',
            accessorFn: row => row.casts ?? '---',
            id: 'casts',
            cell: ({row}) => {
                const spell = row.original;
                return calculateSpellsCasted(castingTable[props.power][spell.rank], spellsCasted[spell.identity]);
            },
            footer: props => props.column.id,
        }
    ]

    return (<Fragment>
        {spellDetail && <Fragment>
            <Dialog open={spellDialogOpen} onBackdropClick={() => setSpellDialogOpen(false)}>
                <SpellCard spell={spellDetail}/>
                <DialogActions>
                    <Button onClick={() => setSpellDialogOpen(false)}>Cancel</Button>
                    <Button
                        disabled={castingTable[props.power][spellDetail.rank] === spellsCasted[spellDetail?.identity]}
                        onClick={castSpell}>Cast</Button>
                </DialogActions>
            </Dialog>
        </Fragment>}
        <Dialog open={!!removeSpell} onBackdropClick={() => setRemoveSpell(null)}>
            <DialogTitle>Are you sure you want to remove {removeSpell?.name}?</DialogTitle>
            <DialogActions>
                <Button onClick={() => setRemoveSpell(null)}>Cancel</Button>
                <Button onClick={() => {
                    props.removeSpell(removeSpell!);
                    setRemoveSpell(null);
                }}>Continue</Button>
            </DialogActions>
        </Dialog>
        {Object.keys(rankedSpells).map((rank: string, index) =>
            (<Fragment key={index}>
                <div className={"flex-row"}>
                    <Typography variant={'subtitle1'}>Rank {rank}</Typography>
                </div>
                <DataTable columns={spellColumns} data={rankedSpells[parseInt(rank)]}/>
            </Fragment>)
        )}
    </Fragment>)
}

export default SpellTable;