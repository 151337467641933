export const meleeAttacks: { [key: string]: Action } = {
    'Driving Attack': {
        banes: 1,
        description: 'You make the attack roll with 1 bane. On a\n' +
            '    success, you and the target move a number of yards equal\n' +
            '    to your Strength modifier in the same direction.'
    },
    'Guarded Attack': {
        banes: 1,
        description: 'You make the attack roll with 1 bane,\n' +
            '    but the next creature to make an attack roll against your\n' +
            '    Defense before the end of the round does so with 1 bane.'
    },
    'Lunging Attack': {
        reach: 1,
        banes: 1,
        description: 'You can increase your reach by 1 yard, but\n' +
            '    you make the attack roll with 1 bane.'
    },
    'Unbalancing Attack': {
        banes: 1,
        description: 'You make the attack roll with 1 bane.\n' +
            '    On a success, if the target is your Size or smaller, it must\n' +
            '    make an Agility challenge roll. On a failure, the target falls'
    }
};

export const rangedAttacks: { [key: string]: Action } = {
    "Called Shot": {
        banes: 2,
        description: `You attack a specific location on the target’s
                      body. You can use this option only if the target has a
                      physical body. Make the attack roll with 2 banes. On a
                      success, the attack has an additional effect as determined
                      by the GM. Attacking a creature’s eyes might impose 1
                      bane on all rolls the target makes that rely on sight, for
                      example.`
    },
    "Distant Shot": {
        banes: 1,
        description: `You can attack a target that is beyond your
                      weapon’s range, but no more than twice the weapon’s
                      range. You make the attack roll with 1 bane.`
    },
    "Staggering Shot": {
        banes: 2,
        description: `You make the attack roll with 2 banes.
                      On a success, a target that is your Size or smaller must
                      make an Agility challenge roll. On a failure, the target falls
                      prone.`
    }
}

export const attributeAttacks: { [key: string]: Action } = {
    "Disarm": {
        description: `Choose one target creature within short range of you
                      that can see you. Make an Intellect attack roll against the
                      target’s Intellect. On a success, the target makes its next
                      attack roll or challenge roll before the end of the round
                      with 2 banes.`
    },
    "Escape": {
        description: `You can use this action if you are grabbed. Make a Strength
                      or Agility attack roll against the Strength of the creature
                      that has grabbed you. A success removes the grabbed
                      affliction and lets you move up to half your Speed. This
                      movement does not trigger free attacks from the creature
                      that had grabbed you. (See Grabbed for information on the
                      effects of being grabbed, and Grab for how to grab.)`
    },
    "Feint": {
        description: `Choose one target creature within short range of you that
                      can see you. Make an Agility attack roll against the target’s
                      Perception. On a success, you make the next attack roll
                      against the target’s Defense or Agility before the end of the
                      next round with 2 boons. Alternatively, your movement
                      does not trigger free attacks from the target for 1 round.`
    },
    "Grab": {
        description: `You must have at least one hand free to grab, and you
                      cannot be grabbing another creature. Choose one target
                      creature within your reach that has a physical body (not a
                      spirit, for example) and make a Strength or Agility attack
                      roll against the target’s Agility. If you choose a creature you
                      are already grabbing, you automatically get a success.
                      On a success, the target becomes grabbed until the end
                      of the next round. If you are prevented from using actions
                      (such as because you are dazed, stunned, or unconscious),
                      the grabbed affliction ends. The grabbed affliction also
                      ends if you move or are moved to a position where you can
                      no longer reach the grabbed target. (See Grabbed for more
                      information on the effects of being grabbed, and Escape for
                      how to escape a grab.)`
    },
    "Knock Down": {
        description: `Choose one target creature within your reach. Make a
                      Strength attack roll against the target’s Agility. If the target
                      is larger than you, make this roll with 1 bane for each point
                      of Size it is larger. You make this roll with 1 boon if the
                      target is smaller than you. On a success, the target falls
                      prone.`
    },
    "Pull": {
        description: `Choose one target creature of your Size or smaller that you
                      are grabbing. Make a Strength attack roll against the target’s
                      Strength. On a success, you move up to half your Speed
                      and the grabbed creature moves with you so that it remains
                      within your reach.`
    },
    "Shove": {
        description: `Choose one target creature within your reach. Make a
                      Strength attack roll against the target’s Strength. If the
                      target is larger than you, you make this roll with 1 bane
                      for each point of Size it is larger. You make this roll with
                      1 boon if the target is smaller than you. On a success, you
                      move the target 1 yard away from you, plus a number of
                      yards equal to your Strength modifier (minimum total
                      distance 1 yard).`
    },
    'Charge': {
        banes: 1,
        description: 'When you use an action to charge, you make attack\n' +
            '    rolls and challenge rolls with 1 bane until the end of the\n' +
            '    round. Move up to your Speed. At any point during your\n' +
            '    movement, make one attack with a melee weapon or with\n' +
            '    an attribute to knock down or shove a creature.'
    }
};

export const actions: { [key: string]: Action } = {
    'Attack': {
        description: `You use a weapon, an attack spell, or something else to
harm or hinder another creature or an object. See Making
Attacks for how to resolve this activity.`
    },
    'Cast a Utility Spell': {
        description: `You cast a utility spell (one that doesn’t count as an attack)
and resolve its effects. See Chapter 7 for details about
casting spells.`
    },
    'Concentrate': {
        description: `Some spell effects and talents require you to concentrate
to keep them going. If you concentrate on an effect, the
effect continues until the end of the next round, up to the
maximum amount of time allowed by the spell.
Breaking Concentration
If you take damage or gain Insanity while you concentrate,
you must make a Will challenge roll. On a failure, you stop
concentrating and the effect ends immediately.`
    },
    'Defend': {
        description: `When you defend, until the end of the round, all attack
rolls are made against you with 1 bane and you make all
challenge rolls to resist attacks with 1 boon. These benefits
end if you are prevented from using actions, such as when
you become dazed, stunned, or unconscious.`
    },
    'End an Effect': {
        description: `Choose one effect you created with a spell you cast or a
talent you used. The effect ends.`
    },
    'Find': {
        description: `You attempt to locate a hidden creature or object. Make a
Perception challenge roll to search for a hidden object or
make a Perception attack roll against the Agility of a hidden
creature. On a success, the creature or object is no longer
hidden from you and any other creature with whom you
share your knowledge.`
    },
    'Help': {
        description: `Choose one creature within 5 yards of you that can see you
and understand what you say. Make an Intellect challenge
roll. On a success, the target makes its next attack roll or
challenge roll before the end of the round with 1 boon.`
    },
    'Hide': {
        description: `You can attempt to hide when you are not being observed

and when you are in a heavily obscured area or have three-
quarters cover or better from an object (see Cover).

Make an Agility challenge roll. On a success, you become
hidden. You remain hidden until the conditions that let
you hide no longer apply or you do something that would
reveal your position (such as shouting, making an attack,
or casting a spell). For example, if you are in darkness,
you would no longer be hidden if the area becomes lit.
Similarly, if you are hidden behind a wall, you would no
longer be hidden if someone moves to a position where the
wall no longer covers you.
While you are hidden, other creatures cannot perceive
you. Generally, this means a creature cannot choose you
as a target for its attack, though you are still subject to area
effects. A creature can guess at your location, making the
attack roll with 3 banes. Even with a success, the attack hits
you only if the guess was correct.
Also, while hidden, you make all attack rolls with 1 boon
against the Defense or Agility of targets from which you are
hidden.`
    },
    'Prepare': {
        description: `You prepare to undertake an activity when a specified event
occurs. Choose one activity you would normally use an
action to perform, such as attack or retreat. Then, describe
what event has to occur for you to undertake it. This is the
trigger.
If the triggering event occurs before the end of the round,
you can use a triggered action to perform the activity. You
make any attack roll or challenge roll required by the
activity with 1 boon. Otherwise, nothing happens.
For example, Bobby takes a fast turn and uses an action
to prepare an attack against the first enemy that moves
into his reach. When a hostile warg moves up to attack his

character, Bobby uses his triggered action to attack it with
his sword, making the attack roll with 1 boon.`
    },
    'Reload': {
        description: `Choose a weapon you can reach that has the reload
property (such as a crossbow). If you have a piece of
ammunition the weapon uses, you reload the weapon.`
    },
    'Retreat': {
        description: `You move up to half your Speed. This movement does not
trigger free attacks.`
    },
    'Rush': {
        description: `You move up to twice your Speed.`
    },
    'Stabilize': {
        description: `Choose one incapacitated creature within your reach. Make
an Intellect challenge roll, with 1 bane if the creature is
dying. On a success, the target heals 1 damage.`
    },
    'Use an Item': {
        description: `You interact with an item you are holding or wearing, or
with an object you can reach. Examples include picking a
lock, retrieving an object from a pouch or a backpack, or
lighting a torch.`
    }
}

export interface Action {
    reach?: number;
    banes?: number;
    description: string;
}
