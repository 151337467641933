import * as React from 'react';
import {AncestryTalents} from "../description-talents-and-skills/AncestryTalents";
import Description from "../description-talents-and-skills/Description";
import Path from "./paths/path/Path";
import Actions from "./primary-actions/Actions";
import Equipment from "./inventory/equipment/Equipment";
import {CharacterModel} from "../Character.model";
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Tab,
    Tabs,
    useScrollTrigger
} from "@mui/material";
import Spells from "./spells/Spells";
import Box from "@mui/material/Box";
import {MenuRounded} from "@mui/icons-material";
import Notes from "./notes/Notes";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import Slide from "@mui/material/Slide";
import ScrollableContainer from "../../../shared/components/ScrollableContainer";

export interface IPrimary {
    character: CharacterModel;
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
    email: string
}

const drawerWidth = 240;
const navItems = ['Actions', 'Spells', 'Equipment', 'Ancestry', 'Path', 'Description', 'Notes'];

function Primary(props: IPrimary) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const tab = useAppSelector((state) => state.application.tab);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{textAlign: 'center'}}
                                        onClick={() => dispatch({type: 'application/setTab', payload: index})}>
                            <ListItemText primary={item}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    function HideOnScroll(props) {
        const {children} = props;
        const trigger = useScrollTrigger();

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}} className={'w-full h-[95%] overflow-hidden'}>
            <Paper sx={{p: 1, m:1}} elevation={1} className={'overflow-hidden'}>
                <Tabs value={tab} onChange={(event, value) => dispatch({type: 'application/setTab', payload: value})}
                      sx={{display: {xs: 'none', sm: 'block'}, padding: '5px'}} variant={'fullWidth'}>
                    {navItems.map((item, index) => (
                        <Tab key={item} label={item} value={index}/>
                    ))}
                </Tabs>
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <MenuRounded/>
                </IconButton>
                <ScrollableContainer>
                    {renderTab(navItems[tab], props.character, props.dispatch, props.email)}
                </ScrollableContainer>
            </Paper>
            <Box component="nav">
                <Drawer
                    container={document.body}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    )
        ;
}

function renderTab(value: string, character: CharacterModel, dispatch: (action: { type: string, payload: { [key: string]: any } }) => void, email: string) {
    switch (value) {
        case 'Actions' :
            return (
                <Actions ancestryTalents={character["Ancestry Talents"] ?? {}} spells={character.Spells ?? []}
                         strength={character.Strength} agility={character.Agility} power={character.Power || 0}/>
            )
        case 'Spells':
            return <Spells editing={false} spellList={character.Spells ?? []} power={character.Power || 0} email={email}
                           spellsCasted={character.spellsCasted}/>
        case 'Equipment' :
            return <Equipment Inventory={character.Inventory} email={email}/>
        case 'Ancestry' :
            return <AncestryTalents talents={character['Ancestry Talents'] ?? {}} dispatch={dispatch}/>
        case 'Path' :
            return <Path talents={character.Paths ?? {}} dispatch={dispatch}/>
        case 'Description' :
            return <Description character={character} dispatch={dispatch}/>
        case 'Notes' :
            return <Notes email={email}/>
        default:
            return <div>Page not found.</div>
    }
}

export default Primary;