import React from "react";
import TextField from '@mui/material/TextField';
import { CharacterModel } from "../character/Character.model";
import { AncestryTalents } from "../character/description-talents-and-skills/AncestryTalents";
import TextArea from "../../shared/components/TextArea";
import Typography from "@mui/material/Typography";

export interface IAncestryForm {
    character: CharacterModel;
    dispatch: (action: { type: string, payload: { [key: string]: any } }) => void;
}


export function AncestryForm(props: IAncestryForm) {
    const name = props.character.Name;
    const ancestry = props.character.Ancestry;
    const languages = props.character.Languages;
    const professions = props.character.Professions;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.dispatch({ type: 'update', payload: { 'Name': (event.target as HTMLInputElement).value } });
    };

    const handleAncestryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.dispatch({ type: 'update', payload: { 'Ancestry': (event.target as HTMLInputElement).value } });
    };

    return (
        <div>
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Character Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name || ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    id="ancestry"
                    label="Ancestry"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={ancestry || ''}
                    onChange={handleAncestryChange}
                />
                <Typography variant={'subtitle1'}>Languages</Typography>
                <TextArea placeholder={'Common'} field={'Languages'} value={languages} dispatch={props.dispatch} />
                <Typography variant={'subtitle1'}>Professions</Typography>
                <TextArea placeholder={'Scholar of the Occult'} field={'Professions'} value={professions} dispatch={props.dispatch} />
            </div>
            <AncestryTalents talents={props.character["Ancestry Talents"] ?? {}} dispatch={props.dispatch} editable={true} />
        </div>
    );
}

